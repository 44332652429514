import React, { useState, useEffect } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RoletaGanhouCardStyle } from "../style";
import BotaoCopiarLink from "../../convite/BotaoCopiarLink";
import BotaoAsALink from "../../botaoAsALink/BotaoAsALink";
import WeexFactory from "../../WeexInputs/WeexFactory";
import BotaoRoleta from "../botaoRoleta/BotaoRoleta";
import RoletaTicketsService from "../../../services/RoletaTicketsService";
import { VoucherRoletaStyle } from "../roletaStyle";
import CampanhaService from "../../../services/CampanhaService";
export function VoucherRoleta({
  title,
  subtitle,
  brindeUuid,
  voucherName,
  voucherDescription,
  linkVoucher,
  firstTime = false,
  ocultar,
  audioRef,
  voucherUuid,
}) {
  const [option] = useState("resultado");
  const [img, setBrindeImg] = useState(null);
  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const roleta = campanha.roulette;

  useEffect(() => {
    audioRef.current.play();
    audioRef.current.volume = 0.3; // Volume reduzido (30%)
  }, [audioRef]);

  useEffect(() => {
    const roletaTicketsService = new RoletaTicketsService();

    //carregar a imagem do brinde
    const fetchImagemBrind = async () => {
      try {
        const response = await roletaTicketsService.getFile(
          "GIVEAWAY",
          "file",
          brindeUuid,
        );

        const url = URL.createObjectURL(response.data);
        setBrindeImg(url);
      } catch (error) {
        console.error("error", error);
      }
    };

    //marca como lido o voucher
    const marcaVoucherComoLido = async () => {
      try {
        if (!brindeUuid || !voucherUuid) {
          return;
        }
        await roletaTicketsService.markVoucherAsRead(brindeUuid, voucherUuid);
      } catch (error) {
        console.error("error", error);
      }
    };

    fetchImagemBrind();
    if (firstTime) {
      marcaVoucherComoLido();
      const carregarTickets = async () => {
        try {
          const roletaTicketService = new RoletaTicketsService();
          const roletaTicketsResponse = await roletaTicketService.obterTickets(
            roleta?.uuid,
          );

          if (roletaTicketsResponse?.data?.tickets?.length) {
            dispatch({
              type: "ticketsSorteados",
              payload: roletaTicketsResponse.data.tickets,
            });
          }
        } catch (error) {
          console.error("Erro ao carregar tickets:", error);
        }
      };
      carregarTickets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brindeUuid]);

  const copyVoucher = () => {
    let i18nMessage = i18n.message("roleta.btn.go.voucher", "Copiar Voucher");
    return (
      <>
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
          <BotaoCopiarLink
            link={linkVoucher}
            texto={i18nMessage}
            erroCopiarLink={erroCopiarLink}
            linkCopiadoComSucesso={linkCopiadoComSucesso}
          />
        </section>
      </>
    );
  };

  const mensagemGestor = () => {
    return (
      <>
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment voucher-gestor ">
          {i18n.message(
            "roleta.voucher.gestor",
            "Precisa de ajuda para encontrar seu voucher? Entre em contato com o gestor da campanha.",
          )}
        </section>
      </>
    );
  };

  const linkCopiadoComSucesso = () => {
    dispatch({ type: "SHOW_MODAL", payload: "success" });
    dispatch({
      type: "error",
      payload: {
        title: i18n.message("roleta.btn.go.voucher.title.copiado", "COPIADO!"),
        message: i18n.message(
          "roleta.btn.go.voucher.msg.copiado",
          "Link copiado com sucesso. Agora é resgatar.",
        ),
      },
    });
    return;
  };

  const erroCopiarLink = () => {
    dispatch({ type: "SHOW_MODAL", payload: "error" });
    dispatch({
      type: "error",
      payload: {
        title: i18n.message(
          "roleta.btn.go.voucher.title.copiado.error",
          "Erro ao copiar link",
        ),
        message: i18n.message(
          "roleta.btn.go.voucher.msg.copiado.error",
          "Entre em contato com o suporte ou tente novamente mais tarde.",
        ),
      },
    });
  };

  const result = () => {
    return (
      <VoucherRoletaStyle>
        <section>
          {title && (
            <h1 className="weexDialog__texts--alignment weexDialog__title">
              {title}
            </h1>
          )}
          {subtitle && (
            <div style={{ marginTop: "1rem", textAlign: "center" }}>
              <span className="weexDialog__texts--alignment weexDialog__texts">
                {subtitle}
              </span>
            </div>
          )}
        </section>
        <RoletaGanhouCardStyle>
          {img ? (
            <div className="roleta-image-container roleta-image-container--premio">
              <img
                src={img}
                alt={i18n.message(
                  "alt.roleta.gift",
                  "Ícone de um presente embrulhado",
                )}
              />
            </div>
          ) : (
            <div className="roleta-image-container roleta-image-container--icone">
              <img
                src="/svgs-3.0/gift.png"
                alt={i18n.message(
                  "alt.roleta.gift",
                  "Ícone de um presente embrulhado",
                )}
              />
            </div>
          )}
          {voucherName && <h3 className="roleta-texto">{voucherName}</h3>}
          {voucherDescription && (
            <p
              className="roleta-texto roleta-texto-descricao"
              dangerouslySetInnerHTML={{ __html: voucherDescription }}
            />
          )}
          {linkVoucher && firstTime && (
            <div className="roleta-voucher-link">
              <span className="roleta-voucher-link--text">{linkVoucher}</span>
            </div>
          )}
        </RoletaGanhouCardStyle>
        {firstTime ? copyVoucher() : mensagemGestor()}
      </VoucherRoletaStyle>
    );
  };

  const options = () => {
    switch (option) {
      case "email":
        return <SendParticipanteEmail ocultar={ocultar} />;
      case "gestor":
        return <SendEmailGestor ocultar={ocultar} />;
      case "resultado":
      default:
        return result();
    }
  };
  return (
    <>
      <main className="weexDialog__main">{options()}</main>
    </>
  );
}

const SendParticipanteEmail = ({ ocultar }) => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState(0);
  const i18n = useStore().getState().i18n;
  let i18nMessage = i18n.message(
    "roleta.btn.go.voucher.email.enviar",
    "Enviar",
  );
  let i18nAriaStatus = i18n.message(
    "aria.status.roleta.btn.go.voucher.email.enviar",
    "Botão Enviar clicado.",
  );

  const sendForm = () => {
    return (
      <>
        <section>
          <h1 className="weexDialog__texts--alignment weexDialog__title">
            {i18n.message("roleta.ganhou.enviar.email", "Receba seu prêmio!")}
          </h1>
          <div style={{ marginTop: "1.5rem" }}>
            <WeexFactory
              id="email"
              type={"email"}
              name={"email"}
              placeholder={i18n.message("roleta.email", "Insira seu e-mail")}
              onChange={() => {}}
              required={true}
              ariaLabel={i18n.message(
                "aria.label.roleta.email",
                "Insira seu e-mail",
              )}
            />
          </div>
        </section>
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
          <BotaoAsALink
            nome={i18n.message("geral.voltar", "Voltar")}
            funcao={() => {}}
          />
          <BotaoRoleta
            nome={i18nMessage}
            funcao={() => {
              setSteps(1);
            }}
            ariaStatusMessage={i18nAriaStatus}
            disabledOnClick={false}
          />
        </section>
      </>
    );
  };

  const finalMessage = () => {
    return (
      <>
        <section>
          <h1 className="weexDialog__texts--alignment weexDialog__title">
            {i18n.message(
              "roleta.ganhou.gestor.email.enviado.title",
              "E-mail enviado com sucesso!",
            )}
          </h1>

          <p
            className="weexDialog__texts--alignment weexDialog__texts"
            style={{ marginTop: "1rem" }}
          >
            {i18n.message(
              "roleta.ganhou.gestor.email.enviado.message",
              "Confira sua caixa de entrada. Caso não encontre o e-mail, não esqueça de olhar seu spam ou lixo eletrônico.",
            )}
          </p>
          <p
            className="weexDialog__texts--alignment weexDialog__texts"
            style={{ marginTop: "1rem" }}
          >
            {i18n.message(
              "roleta.ganhou.gestor.email.enviado.message",
              "Se mesmo assim não tiver recebido seu vaucher você poderá reenvia-lo através da página de conquistas. ",
            )}
          </p>
        </section>
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
          <BotaoAsALink
            nome={i18n.message(
              "dinamica.fatofakeRevisao.voltarAtividades",
              "FECHAR",
            )}
            funcao={() => {
              ocultar();
            }}
          />
          <BotaoRoleta
            nome={i18n.message("roleta.btn.go.conquistas", "Conquistas")}
            funcao={() => {
              navigate("/trophy");
              ocultar();
            }}
            ariaStatusMessage={i18n.message(
              "aria.status.roleta.btn.go.conquistas",
              "Botão Conquistas clicado.",
            )}
            disabledOnClick={false}
          />
        </section>
      </>
    );
  };

  const sendSteps = () => {
    switch (steps) {
      case 1:
        return finalMessage();
      case 0:
      default:
        return sendForm();
    }
  };
  return <>{sendSteps()}</>;
};

const SendEmailGestor = ({ ocultar }) => {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <section>
        <h1 className="weexDialog__texts--alignment weexDialog__title">
          {i18n.message(
            "roleta.ganhou.gestor.email.title",
            "Gift card enviado para o gestor!",
          )}
        </h1>

        <p
          className="weexDialog__texts--alignment weexDialog__texts"
          style={{ marginTop: "1rem" }}
        >
          {i18n.message(
            "roleta.ganhou.gestor.email.message",
            "Enviamos seu vaucher para o gestor da sua campanha. Entre em contato com ele para prosseguir com o seu recebimento.",
          )}
        </p>
      </section>
      <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
        <BotaoAsALink
          nome={i18n.message(
            "dinamica.fatofakeRevisao.voltarAtividades",
            "FECHAR",
          )}
          funcao={() => {
            ocultar();
          }}
        />
      </section>
    </>
  );
};
