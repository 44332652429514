import React from "react";
import { AgendaHeaderStyle } from "./style";
import CampanhaService from "../../../services/CampanhaService";
import ProgressoCircular from "../barraProgresso/ProgressoCircular";
import BotaoGameAtividade from "../BotaoGameAtividade/BotaoGameAtividade";
import DataAgenda from "../CardzinhoDataDaAgenda/DataAgenda";
import BotaoRoletaAgenda from "../BotaoRoletaAgenda/BotaoRoletaAgenda";

function AgendaHeader({
  navegarRoleta,
  scheduleCode,
  habilitado,
  concluidasPorcentagem,
  nome,
  nomeInteresse,
  dayOfWeekComplete,
  diaTextual,
  mesTextualVisivel,
  mesTextualLeitorTela,
  isAgendaProlongada,
  possuiSubtitulos,
  atividadesSemGameFinal,
  gamesFinal,
  agendaSemContraTrofeus,
}) {
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const shouldShowScheduleDate =
    campanha?.personalizationEvent?.SHOW_DATE_SCHEDULE === "true";
  const roletaAtividade = campanha?.roulette;
  let title;

  if (nome) {
    title = nome;
  } else if (nomeInteresse) {
    title = nomeInteresse;
  } else {
    if (!isAgendaProlongada && shouldShowScheduleDate) {
      title = dayOfWeekComplete;
    }
  }

  const roleta = () => {
    if (roletaAtividade) {
      return (
        <>
          <BotaoRoletaAgenda
            navegarRoleta={navegarRoleta}
            scheduleCode={scheduleCode}
            roleta={roletaAtividade}
            atividades={
              possuiSubtitulos
                ? atividadesSemGameFinal.filter((a) => a?.subtitle?.uuid)
                : atividadesSemGameFinal
            } // Para saber se todas as atividades foram realizadas ou não
          />
        </>
      );
    }
  };

  const atividadesGame = () => {
    return (
      <>
        <BotaoGameAtividade
          games={
            possuiSubtitulos
              ? gamesFinal.filter((a) => a?.subtitle?.uuid)
              : gamesFinal
          }
          atividades={
            possuiSubtitulos
              ? atividadesSemGameFinal.filter((a) => a?.subtitle?.uuid)
              : atividadesSemGameFinal
          }
        />
      </>
    );
  };

  return (
    <AgendaHeaderStyle>
      <div className="agendaHeader__alignedSubelements">
        <DataAgenda
          habilitado={habilitado}
          diaTextual={diaTextual}
          mesTextualVisivel={mesTextualVisivel}
          mesTextualLeitorTela={mesTextualLeitorTela}
          isAgendaProlongada={isAgendaProlongada}
        />
        <h5>{title}</h5>
      </div>
      <div className="agendaHeader__alignedSubelements">
        {roleta()}
        {atividadesGame()}
        {!agendaSemContraTrofeus && (
          <ProgressoCircular porcentagemFinal={concluidasPorcentagem} />
        )}
      </div>
    </AgendaHeaderStyle>
  );
}

export default AgendaHeader;
