import React, { useCallback, useEffect, useState } from "react";
import { RoletaHistoricoStyle } from "./style";
import PagesHeader from "../../comps/pagesHeader/PagesHeader";
import { useStore } from "react-redux";
import DataAgenda from "../../comps/agenda/CardzinhoDataDaAgenda/DataAgenda";
import RoletaTicketsService from "../../services/RoletaTicketsService";
import CampanhaService from "../../services/CampanhaService";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import {
  buildActionsTickets,
  buildDataAgenda,
  buildFormatacaoTickets,
} from "./RoletaHistoricoHelp";
import ModalRoletaComprovanteHistorico from "../../comps/weexModais/ModalRoletaComprovanteHistorico";
import { MissaoCumpridaFeedbackModalStyle } from "../../comps/weexModais/StyleMissaoCumpridaFeedback";
import { WeexModalDialog } from "../../comps/weexModais/WeexModalDialog";

function RoletaHistorico() {
  const i18n = useStore().getState().i18n;

  const [tickets, setTickets] = useState([]);
  const [properties, setProperties] = useState(new Map());

  const [ativaModalComprovante, setAtivaModalComprovante] = useState(false);
  const [mostarModalVaucher, setMostarModalVaucher] = useState(false);
  const dialogRef = React.createRef();

  //ticketProps para atualizar o Modal corretamente
  const [ticketProps, setTicketProps] = useState(null);

  const irParaBaixaComprovante = useCallback((ticket) => {
    setTicketProps(ticket);
    setAtivaModalComprovante(true);
  }, []);

  const irParaRecuperaVaucher = useCallback(() => {
    setMostarModalVaucher(true);
  }, []);
  /**
   * Faz o fetch dos tickets e monta o estado de tickets e properties
   */
  const modalGestorVaucher = () => {
    return (
      <>
        <WeexModalDialog
          className="comprovante-container"
          dialogRef={dialogRef}
          onPressESC={() => setMostarModalVaucher(false)}
        >
          <div
            className="modal is-active animations-popIn comprovante-container"
            style={{ zIndex: "102" }}
          >
            <div>
              <header className="modal-card-head weex-modal-card-header">
                <button
                  className="close-btn"
                  style={{ zIndex: "1003" }}
                  aria-label={i18n.message(
                    "aria.label.fechar.modal",
                    "Fecha a janela e volta para a página.",
                  )}
                  onClick={() => setMostarModalVaucher(false)}
                >
                  <img
                    src="/close-btn.png"
                    alt={i18n.message(
                      "alt.button.imagem.fechar",
                      "ícone em formato de um X indicando o encerramento de uma ação.",
                    )}
                  />
                </button>
              </header>
              <MissaoCumpridaFeedbackModalStyle>
                {i18n.message(
                  "roleta.voucher.gestor",
                  "Precisa de ajuda para encontrar seu voucher? Entre em contato com o gestor da campanha.",
                )}
              </MissaoCumpridaFeedbackModalStyle>
            </div>
          </div>
        </WeexModalDialog>
      </>
    );
  };

  const carregarTickets = useCallback(async () => {
    try {
      const roletaTicketService = new RoletaTicketsService();
      const campanhaService = new CampanhaService();
      const roleta = campanhaService.getRoleta();

      const roletaTicketsResponse = await roletaTicketService.obterTickets(
        roleta.uuid,
      );
      if (roletaTicketsResponse?.data) {
        const fetchedTickets = roletaTicketsResponse.data.tickets;

        setTickets(fetchedTickets);
        let newProps = buildDataAgenda(fetchedTickets, i18n);
        newProps = buildFormatacaoTickets(fetchedTickets, newProps);
        newProps = buildActionsTickets(
          fetchedTickets,
          newProps,
          i18n,
          irParaRecuperaVaucher,
          irParaBaixaComprovante,
        );
        setProperties(newProps);
      }
    } catch (error) {
      console.error(error);
    }
  }, [i18n, irParaRecuperaVaucher, irParaBaixaComprovante]);

  /**
   * useEffect para carregar os tickets sempre que `i18n` ou as actions mudarem
   */
  useEffect(() => {
    carregarTickets();
  }, [carregarTickets]);

  /**
   * param {import('../../services/RoletaTicketsService').Ticket} ticket
   * */

  const getItem = (ticket) => {
    return (
      <div key={ticket.uuid} className="ticket">
        {/*HEADER*/}
        <div className="ticketHeader__alignedSubelements">
          <DataAgenda
            habilitado={true}
            diaTextual={properties.get(ticket.uuid).day}
            mesTextualVisivel={properties.get(ticket.uuid).mesTextualVisivel}
            mesTextualLeitorTela={
              properties.get(ticket.uuid).mesTextualLeitorTela
            }
            isAgendaProlongada={false}
          />
          <span className="ticketHeader">{ticket.scheduleName}</span>
        </div>
        <div className="ticketBody">
          <p className="ticketBody__brindeNome">{ticket.giveawayName}</p>
          {ticket.giveawayType === "PONTOS" && (
            <span className="ticketBody__PONTOS">
              {ticket.giveawayProperties.value}{" "}
              <p className="ticketBody__PONTOS--pontos">
                {i18n.message("geral.pontos", "pts")}
              </p>
            </span>
          )}
          <div style={{ width: "100%" }}>
            {ticket.giveawayType !== "PONTOS" && (
              <div className="ticketBtn">
                <PrimarioBtn
                  ariaDisabled="true"
                  ariaHidden="true"
                  tabIndex="-1"
                  nome={properties.get(ticket.uuid).btnLabel}
                  funcao={() => properties.get(ticket.uuid).action()}
                  ariaStatusMessage={properties.get(ticket.uuid).btnLabel}
                ></PrimarioBtn>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <RoletaHistoricoStyle>
      {ativaModalComprovante ? (
        <ModalRoletaComprovanteHistorico
          brindeImg={ticketProps?.uuid}
          brindeNome={ticketProps?.giveawayName}
          nome={ticketProps?.participantName}
          matricula={ticketProps.enrollment}
          unidade={ticketProps.unidadeFormatted}
          dataSorteio={ticketProps.dataSorteioFormatted}
          fecharModal={() => setAtivaModalComprovante(false)}
          brindeUuid={ticketProps.uuidBrinde}
          ticketUuid={ticketProps.uuid}
        ></ModalRoletaComprovanteHistorico>
      ) : (
        ""
      )}
      <PagesHeader
        title={i18n.message("roletaHistorico.titulo", "Histórico da Roleta")}
      />
      {tickets.length === 0 && (
        <div className="ticketEmpty">
          <p>
            {i18n.message(
              "roletaHistorico.semTickets",
              "Sem histórico de prêmios",
            )}
          </p>
        </div>
      )}
      <div className="ticketItems">
        {tickets.map((ticket) => {
          return getItem(ticket);
        })}
      </div>
      {mostarModalVaucher ? modalGestorVaucher() : ""}
    </RoletaHistoricoStyle>
  );
}

export default RoletaHistorico;
