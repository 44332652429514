import React from "react";
import { useSelector } from "react-redux";
import { AvatarModal } from "../header/avatar/AvatarModal";
import WeexModal from "./WeexModal";
import MensagemVideoModal from "./MensagemVideoModal";
import EntrarEquipeModal from "../team/modal/EntrarEquipeModal";
import { RoletaModal } from "../roleta/RoletaModal";

export function WeexModalFactory() {
  const modals = useSelector((state) => state.modals);
  return (
    <>
      {modals?.avatar && <AvatarModal />}
      {modals?.entrarEquipe && <EntrarEquipeModal />}
      {modals?.error && <WeexModal />}
      {modals?.success && <WeexModal />}
      {modals?.welcomeVideo && <MensagemVideoModal />}
      {modals?.roulette && <RoletaModal />}
    </>
  );
}
