// TODO: Refatorar para separar as regras em serviços
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useStore, useSelector } from "react-redux";
import CampanhaService from "../../services/CampanhaService";
import { PontuacaoRoleta } from "./resultado/PontuacaoRoleta";
import { BrindeRoleta } from "./resultado/BrindeRoleta";
import { VoucherRoleta } from "./resultado/VoucherRoleta";
import BotaoFechar from "../BotaoFechar/BotaoFechar";
import { Roleta } from "./Roleta";
import { RoletaModalStyle } from "./style";
import BotaoRoleta from "./botaoRoleta/BotaoRoleta";
import RoletaTicketsService from "../../services/RoletaTicketsService";
import UsuarioService from "../../services/UsuarioService";
import WeexModal from "../weexModais/WeexModal";
import { useNavigate } from "react-router-dom";
import NotificadorService from "../../services/NotificadorService";
import { AnimatedComponent } from "./roletaStyle";

const campanhaService = new CampanhaService();
const roletaTicketsService = new RoletaTicketsService();

export function RoletaModal() {
  const comemoracaoRef = useRef(new Audio("/sound/congratulations.mp3"));
  const roletaAudioRef = useRef(new Audio("/sound/roleta.mp3"));
  const [targetReward, setTargetReward] = useState(null);
  const [isRotating, setIsRotating] = useState(null);
  const scheduleCode = useSelector((state) => state.scheduleCode);
  const i18n = useStore().getState().i18n;
  const notification = useSelector((state) => state.notification);
  const ticketsSorteados = useSelector((state) => state.ticketsSorteados);
  const dialogRef = useRef(null);
  const campanha = campanhaService.campanhaCorrente();
  const roulette = campanha?.roulette;
  const [step, setStep] = useState("LOADING");
  const dispatch = useDispatch();
  const usuario = new UsuarioService().usuarioCorrente();
  const navigate = useNavigate();
  const notificadorService = new NotificadorService();
  const [jaSorteado, setJaSorteado] = useState(true);
  const usuarioCorrente = new UsuarioService().usuarioCorrente();

  const tempoEsperaAtePooling =
    process.env.REACT_APP_HOST_TEMPO_ESPERA_ATE_POOLING || 2000;
  const retentativaPooling =
    process.env.REACT_APP_HOST_RETENTATIVA_POOLING || 5;
  const tempoEsperaEntreTentativas =
    process.env.REACT_APP_HOST_TEMPO_ESPERA_ENTRE_TENTATIVAS || 1500;

  const [ticket, setTicket] = useState({});
  const [erro, setErro] = useState(null);
  const roleta = campanha.roulette;

  //verificar se o codigo da agenda é diferente de null
  useEffect(() => {
    //se houver valor  salvar no localstorage
    if (scheduleCode) {
      localStorage.setItem("scheduleCode", scheduleCode);
    }
    //se o código da agenda for nulo, buscar no localstorage
    if (!scheduleCode) {
      const scheduleCode = localStorage.getItem("scheduleCode");
      dispatch({ type: "scheduleCode", payload: scheduleCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleCode]);

  useEffect(() => {
    let matchedTicket = ticketsSorteados.find(
      (ticket) => ticket.scheduleUuid === scheduleCode,
    );

    if (matchedTicket) {
      setJaSorteado(true);
      const ticketFormat = ticketFormatado(matchedTicket);
      setTicket(ticketFormat);
      setStep("SPIN");
      switch (matchedTicket.giveawayType) {
        case "BRINDE":
          setStep("BRINDE");
          break;
        case "VOUCHER":
          setStep("VOUCHER");
          break;
        default:
          setStep("PONTOS");
      }
    } else {
      setJaSorteado(false);
      setStep("SPIN");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCancel(e) {
    e.preventDefault();
    const { current } = dialogRef;
    current.close();
    ocultar();
  }

  const openDialog = useCallback(() => {
    const { current } = dialogRef;
    current.close();
    current.showModal();
  }, [dialogRef]);

  useEffect(() => {
    openDialog();
  }, [openDialog]);

  const ticketFormatado = (ticket) => {
    //deep copy
    const ticketFormatado = JSON.parse(JSON.stringify(ticket));
    ticketFormatado.unidadeFormatted = formataUnidade(
      ticketFormatado.unit,
      ticketFormatado?.subUnit,
      ticketFormatado?.level3,
    );
    // eslint-disable-next-line max-len
    ticketFormatado.dataSorteioFormatted = formatarData(
      new Date(ticketFormatado.date),
    );
    return ticketFormatado;
  };

  useEffect(() => {
    if (jaSorteado) {
      return;
    }
    async function buscarNotificacao() {
      if (notification) {
        if (!scheduleCode) {
          return;
        }
        const notificacao = notification.find(
          (notificacao) =>
            notificacao?.scheduleCode === scheduleCode &&
            notificacao?.action === "ROLETA",
        );
        if (notificacao) {
          if (notificacao.isSucess && roleta) {
            const roletaTicketService = new RoletaTicketsService();
            try {
              const roletaTicketsResponse =
                await roletaTicketService.obterTickets(roleta.uuid, {
                  uuid: notificacao.ticket,
                });
              if (roletaTicketsResponse?.data.tickets.length > 0) {
                const firstTicket = ticketFormatado(
                  roletaTicketsResponse?.data.tickets[0],
                );
                setTicket(firstTicket);
                setJaSorteado(true);
                setTargetReward(firstTicket.giveawayUuid);
              } else {
                // Handle case of no tickets
                setErro({
                  titulo: i18n.message(
                    "roleta.erro.obter.sorteio",
                    "Erro ao obter sorteio",
                  ),
                  detalhe: "Nenhum ticket encontrado",
                });
              }
            } catch (error) {
              setErro({
                titulo: i18n.message(
                  "roleta.erro.obter.sorteio",
                  "Erro ao obter sorteio",
                ),
                detalhe: error.message,
              });
              resetarAudio();
            }
          } else {
            console.error("Notificação de sorteio não foi bem sucedida");
          }
        }
      }
    }

    buscarNotificacao();
    carregarTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification, scheduleCode, i18n]);

  const carregarTickets = async () => {
    const roletaTicketService = new RoletaTicketsService();
    const roletaTicketsResponse = await roletaTicketService.obterTickets(
      roleta?.uuid,
    );
    if (roletaTicketsResponse?.data) {
      const fetchedTickets = roletaTicketsResponse.data.tickets;
      dispatch({ type: "ticketsSorteados", payload: fetchedTickets });
    }
  };

  /* eslint-disable no-unused-vars */
  const pulling = () => {
    //todo função real de pooling
    let delayTimeoutId;
    let intervalId;

    let tentativas = 0; // Inicializar contador de tentativas

    // Função que realiza o pooling
    const iniciarPooling = () => {
      const verificarSorteio = () => {
        tentativas += 1; // Incrementar número de tentativas

        // Se já sorteado, interrompe o pooling
        if (jaSorteado) {
          clearInterval(intervalId);
          clearTimeout(delayTimeoutId);
          return;
        }

        // Se o número de tentativas for maior ou igual ao limite, para o pooling
        if (tentativas >= retentativaPooling) {
          clearInterval(intervalId);
          clearTimeout(delayTimeoutId);
          console.error("Número de tentativas excedido. Finalizando pooling.");
          setErro({
            titulo: i18n.message(
              "roleta.erro.obter.sorteio",
              "Erro ao obter sorteio",
            ),
            detalhe: i18n.message(
              "roleta.erro.obter.sorteio.detalhe",
              "Número de tentativas excedido",
            ),
          });
          return;
        }

        // Lógica de busca de notificação
        notificadorService
          .buscarMensagensParticipante({
            tag: scheduleCode,
            userId: usuarioCorrente.participantCode,
          })
          .then((response) => {
            setIsRotating(false);
            const notificacao = response?.data?.data?.[0];

            if (!notificacao) {
              throw new Error("Notificação não encontrada");
            }

            const ticketParam = notificacao.parameters.find(
              (param) => param.code === "ticket",
            );
            const isSucessParam = notificacao.parameters.find(
              (param) => param.code === "isSucess",
            );

            if (ticketParam && isSucessParam && roleta) {
              const roletaTicketService = new RoletaTicketsService();
              return roletaTicketService.obterTickets(roleta.uuid, null);
            }
          })
          .then((ticketsResponse) => {
            if (ticketsResponse?.data?.tickets) {
              const firstTicket = ticketFormatado(
                ticketsResponse.data.tickets[0],
              );
              clearInterval(intervalId);
              clearTimeout(delayTimeoutId);
              setTicket(firstTicket);
              setJaSorteado(true);
              setIsRotating(false);
              setTargetReward(firstTicket.giveawayUuid);
            }
          })
          .catch((error) => {
            resetarAudio();
            setIsRotating(false);
            console.error("Erro ao buscar notificação:", error);
          });
      };

      // Iniciar o intervalo para verificar sorteio
      intervalId = setInterval(verificarSorteio, tempoEsperaEntreTentativas);
    };

    // Aguardar antes de iniciar o pooling
    delayTimeoutId = setTimeout(() => {
      iniciarPooling();
    }, tempoEsperaAtePooling);

    // Retornar função para limpar intervalos/tempos pendentes
    return () => {
      if (delayTimeoutId) {
        clearTimeout(delayTimeoutId);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  };

  const resetarAudio = () => {
    if (!comemoracaoRef.current.paused) {
      comemoracaoRef.current.pause(); // Para o áudio se ele estiver tocando
      comemoracaoRef.current.currentTime = 0; // Reseta o áudio para o início
    }
    if (!roletaAudioRef.current.paused) {
      roletaAudioRef.current.pause(); // Para o áudio se ele estiver tocando
      roletaAudioRef.current.currentTime = 0; // Reseta o áudio para o início
    }
  };

  const ocultar = () => {
    if (isRotating) {
      return;
    }
    resetarAudio();
    dispatch({ type: "HIDE_MODAL", payload: "roulette" });
  };

  const onSpin = () => {
    setIsRotating(true);
    roletaTicketsService
      .spinRoulette(scheduleCode)
      .then(() => {
        salvaLocalStorageSorteio(scheduleCode);
      })
      .catch((error) => {
        resetarAudio();
      });
  };

  const spinningRoullete = () => {
    onSpin();
    pulling();
  };

  const formatarData = (data) => {
    if (data) {
      const dia = String(data.getDate()).padStart(2, "0");
      const mes = String(data.getMonth() + 1).padStart(2, "0"); // Os meses começam do zero
      const ano = data.getFullYear();
      const horas = String(data.getHours()).padStart(2, "0");
      const minutos = String(data.getMinutes()).padStart(2, "0");
      return `${dia}/${mes}/${ano} às ${horas}:${minutos}`;
    }

    return "";
  };

  const salvaLocalStorageSorteio = (uuidAgenda) => {
    const chaveSorteio = `sorteio-${uuidAgenda}-${usuario.participantCode}`;
    localStorage.setItem(chaveSorteio, Date.now());
  };

  const formataUnidade = (unidade, subunidade, nivel3) => {
    if (nivel3) {
      return `${unidade} / ${subunidade} / ${nivel3}`;
    } else if (subunidade) {
      return `${unidade} / ${subunidade}`;
    } else if (unidade) {
      return unidade;
    }
    return "";
  };

  const callbackWarning = () => {
    setErro(null);
    navigate("/atividades");
  };

  const stopRoleta = () => {
    setTimeout(() => {
      if (ticket) {
        switch (ticket.giveawayType) {
          case "BRINDE":
            setStep("BRINDE");
            break;
          case "VOUCHER":
            setStep("VOUCHER");
            break;
          default:
            setStep("PONTOS");
        }
      }
    }, 1000);
  };

  const presentation = () => {
    return (
      <>
        <main className="weexDialog__main">
          <section>
            <h1
              className="weexDialog__texts--alignment weexDialog__title"
              dangerouslySetInnerHTML={{
                __html: roulette?.customization?.title,
              }}
            />
            <div style={{ marginTop: "1rem" }}>
              <span
                className="weexDialog__texts--alignment weexDialog__subtitle"
                dangerouslySetInnerHTML={{
                  __html: roulette?.customization?.subtitle,
                }}
              />
            </div>
          </section>
          {
            <Roleta
              isRotating={isRotating}
              customization={roulette?.customization}
              slices={roulette?.slices}
              targetReward={targetReward}
              audioRef={roletaAudioRef}
              stopRoleta={stopRoleta}
            />
          }
          {buttons()}
        </main>
      </>
    );
  };

  const steps = () => {
    switch (step) {
      case "LOADING":
        return (
          <main className="weexDialog__main">
            <section>
              <h1 className="weexDialog__texts--alignment weexDialog__title">
                {i18n.message("roleta.carregando", "Carregando...")}
              </h1>
            </section>
          </main>
        );
      case "PONTOS":
        return (
          <AnimatedComponent>
            <PontuacaoRoleta
              title={i18n.message(
                "roleta.premio.titulo",
                "Você está com sorte!",
              )}
              subtitle={i18n.message("roleta.premio.subtitulo", "Ganhou...")}
              uuid={ticket?.uuid}
              pointsEarned={ticket?.giveawayProperties?.value}
              brindeUuid={ticket.giveawayUuid}
              competitiveType={campanha?.campaignType}
              ocultar={ocultar}
              audioRef={comemoracaoRef}
            />
          </AnimatedComponent>
        );
      case "BRINDE":
        return (
          <AnimatedComponent>
            <BrindeRoleta
              title={i18n.message(
                "roleta.premio.titulo",
                "Você está com sorte!",
              )}
              subtitle={i18n.message("roleta.premio.subtitulo", "Ganhou...")}
              brindeUuid={ticket.giveawayUuid}
              dataSorteio={ticket.dataSorteioFormatted}
              unidade={ticket.unidadeFormatted}
              nomeParticipante={ticket?.participantName}
              matricula={ticket.enrollment}
              uuid={ticket?.uuid}
              brindeName={ticket?.giveawayName}
              brindeDescription={ticket?.giveawayDescription}
              donwloadComprovante={true}
              audioRef={comemoracaoRef}
            />
          </AnimatedComponent>
        );
      case "VOUCHER":
        return (
          <AnimatedComponent>
            <VoucherRoleta
              title={i18n.message(
                "roleta.premio.titulo",
                "Você está com sorte!",
              )}
              subtitle={i18n.message("roleta.premio.subtitulo", "Ganhou...")}
              uuid={ticket?.uuid}
              brindeUuid={ticket.giveawayUuid}
              voucherName={ticket?.giveawayName}
              voucherDescription={ticket?.giveawayDescription}
              linkVoucher={ticket?.voucherCode}
              firstTime={!ticket?.read}
              ocultar={ocultar}
              audioRef={comemoracaoRef}
              voucherUuid={ticket?.voucherUuid}
            />
          </AnimatedComponent>
        );
      case "SPIN":
      default:
        return presentation();
    }
  };

  const buttons = () => {
    let i18nMessage = roulette?.customization?.textButton;
    let i18nAriaStatus = i18n.message(
      "aria.status.roleta.btn.girar",
      "Botão girar clicado.",
    );
    return (
      <>
        {erro != null ? (
          <WeexModal
            fecharModalCallback={callbackWarning}
            titulo={erro.titulo}
            conteudo={erro.mensagem}
          />
        ) : null}
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
          <BotaoRoleta
            nome={i18nMessage}
            funcao={spinningRoullete}
            ariaStatusMessage={i18nAriaStatus}
            disabledOnClick={isRotating}
          />
        </section>
      </>
    );
  };

  return (
    <>
      <RoletaModalStyle ref={dialogRef} onCancel={onCancel}>
        <BotaoFechar funcao={ocultar} />
        {steps()}
      </RoletaModalStyle>
    </>
  );
}
