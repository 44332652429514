import styled from "styled-components";
import { PrimarioBtnStyle } from "../../buttonPWA2.0/style";

export const BotaoRoletaStyle = styled(PrimarioBtnStyle)`
  z-index: 100;
  position: relative;
  background-color: ${(props) => {
    return props.theme.FUNDO_BOTAO;
  }};
  color: ${(props) => {
    return props.theme.TEXTO_BOTAO;
  }};

  .content-botao {
    .ql-size-huge {
      font-size: 1.625rem; // 26px;
    }

    .ql-size-large {
      font-size: 0.938rem; //15px;
    }

    .ql-size-small {
      font-size: 0.875rem; //14px;
    }
    p {
      font-size: 0.938rem; // 15px; default. Mudar através de classes.
      font-weight: 400; // default. Mudar através de classes.
      line-height: 1.221rem; //19.53px; default. Mudar através de classes.
      text-align: left; // default. Mudar através de classes.
      color: ${(props) => {
        return props.theme.TEXTO_BOTAO;
      }};
    }
  }
  .baseButton__content--link {
    color: ${(props) => {
      return props.theme.TEXTO_BOTAO;
    }};
  }
`;
