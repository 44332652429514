/**
 * Retorna informações textuais sobre a data fornecida.
 * @param {Date} date - A data para a qual obter informações.
 * @param {Object} i18n - O objeto i18n.
 * @returns {Object} Informações textuais da data.
 */
export const getDataAgenda = (date, i18n) => {
  if (date) {
    let dataString = date;

    let dataSemHora = dataString.split("T")[0];

    const componentesData = dataSemHora?.split("-");
    const day = componentesData[2];
    const dayOfWeek = diaDaSemanaByDate(date);
    const dayOfWeekComplete = i18n.getNomeDiaSemana(dayOfWeek);
    const mesTextualVisivel = i18n.getNomeMesAbreviado(componentesData[1]);
    const mesTextualLeitorTela = i18n.getNomeMesInteiro(componentesData[1]);

    return {
      day,
      dayOfWeek,
      dayOfWeekComplete,
      mesTextualVisivel,
      mesTextualLeitorTela,
    };
  }
  return null;
};

/**
 * Retorna o nome abreviado do dia da semana com base na data.
 * @param {Date} string - A data para a qual obter o dia da semana.
 * @returns {string} O nome abreviado do dia da semana.
 */
const diaDaSemanaByDate = (dateString) => {
  const date = new Date(dateString);
  const diasDaSemana = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];
  const dayOfWeek = date.getDay();
  return diasDaSemana[dayOfWeek];
};
