import CampanhaService from "./CampanhaService";

const campanhaService = new CampanhaService();

export default class RoletaCustomizacaoService {
  constructor() {
    this.template = campanhaService.getRoleta();
    this.finalTemplate = {};
  }

  load() {
    this.init(this.template);
  }

  init(template) {
    let finalTemplateObj = {};
    if (template && template.customization && template.customization.colors) {
      for (let c of template.customization.colors) {
        finalTemplateObj[c.code] = c.color;
      }
    }
    this.finalTemplate = finalTemplateObj;
  }
}
