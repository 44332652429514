/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import { RoletaStyle, SetaStyle, ManivelaStyle } from "./roletaStyle";

export function Roleta({
  isRotating,
  customization,
  slices,
  targetReward,
  audioRef,
  stopRoleta,
}) {
  const size = 263; // Tamanho do SVG
  const radius = size / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const wheelRef = useRef(null);
  const [map, setMap] = useState(new Map());

  useEffect(() => {
    // Configurações do som
    audioRef.current.playbackRate = 2.5; // Tocar mais rápido (2.5x)
    audioRef.current.volume = 0.8; // Volume reduzido (80%)
  }, [audioRef]);

  useEffect(() => {
    let sliceMap = new Map();
    const totalSlices = slices.length;
    const sliceAngle = (360 / totalSlices) * (Math.PI / 180);

    slices.forEach((slice, index) => {
      const inicio = index * sliceAngle;
      const centro = inicio + sliceAngle / 2;
      sliceMap.set(slice?.giveaway?.uuid, {
        sliceAngle: index * sliceAngle,
        centerAngle: centro,
        isEven: slice.position % 2 === 0,
        type: slice?.giveaway?.type,
      });
    });

    setMap(sliceMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slices]);

  useEffect(() => {
    startRotation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRotating]);

  useEffect(() => {
    stopRotation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRotating, targetReward]);

  const startRotation = () => {
    if (isRotating === true) {
      // Reproduz o som
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      // Digamos que queira 2 voltas completas no início
      const spinsStart = 10;
      const totalRotationStart = spinsStart * 2 * Math.PI;

      // - sliceAngle/2, se você ainda quiser alinhar “meio” da fatia
      // (pode remover se não fizer diferença no giro inicial)
      let finalRotationStart = totalRotationStart;

      wheelRef.current.style.transition = "transform 6s ease-out";
      wheelRef.current.style.transform = `rotate(${finalRotationStart}rad)`;
    }
  };

  const stopRotation = () => {
    if (isRotating === false && targetReward) {
      // Total de fatias
      // Pegamos do map a informação da fatia
      const targetSlice = map.get(targetReward);

      if (!targetSlice) {
        console.error("Fatia alvo não encontrada no map!");
        return;
      }

      const centerAngle = targetSlice.centerAngle;

      // Defina quantas voltas completas você quer
      const spins = 14;
      // Em radianos, 5 voltas = 5 * 2π
      const totalRotation = spins * 2 * Math.PI;

      // Some a meia fatia para alinhar no centro
      const finalRotation = totalRotation - centerAngle - Math.PI / 2;

      // Animação
      wheelRef.current.style.transition = "transform 5s ease-out";
      wheelRef.current.style.transform = `rotate(${finalRotation}rad)`;

      // Diminuição de volume
      let volume = audioRef.current.volume;
      const volumeDecrement = 0.05;
      const intervalTime = 100;

      const volumeDecreasingInterval = setInterval(() => {
        if (volume > 0) {
          volume -= volumeDecrement;
          audioRef.current.volume = Math.max(0, volume);
        } else {
          clearInterval(volumeDecreasingInterval);
          audioRef.current.pause();
        }
      }, intervalTime);

      setTimeout(() => {
        stopRoleta();
      }, 5000);
    }
  };

  const getTextX = (i) => {
    const anglePerPiece = (2 * Math.PI) / customization.slices.length;
    return (
      radius +
      0.5 * // Ajuste aqui para mover o texto mais para fora
        radius *
        Math.cos(i * anglePerPiece + anglePerPiece / 2)
    );
  };

  const getTextY = (i) => {
    const anglePerPiece = (2 * Math.PI) / customization.slices.length;
    return (
      radius +
      0.5 * // Ajuste aqui para mover o texto mais para fora
        radius *
        Math.sin(i * anglePerPiece + anglePerPiece / 2)
    );
  };

  const getTextRotation = (index) => {
    const anglePerPiece = (2 * Math.PI) / customization.slices.length;
    return (index * anglePerPiece + anglePerPiece / 2) * (180 / Math.PI);
  };

  const getPiePath = (index) => {
    const cx = radius;
    const cy = radius;
    const anglePerPiece = (2 * Math.PI) / customization.slices.length; // Ângulo fixo por pedaço
    const startAngle = index * anglePerPiece;
    const endAngle = startAngle + anglePerPiece;

    // Cálculo dos pontos
    const startX = cx + radius * Math.cos(startAngle);
    const startY = cy + radius * Math.sin(startAngle);
    const endX = cx + radius * Math.cos(endAngle);
    const endY = cy + radius * Math.sin(endAngle);

    // Desenho do caminho do gráfico de pizza (sector path)
    return `
      M ${cx},${cy} 
      L ${startX},${startY} 
      A ${radius},${radius} 0 0,1 ${endX},${endY} 
      Z
    `;
  };

  const wheel = () => {
    return (
      <>
        <svg
          ref={wheelRef}
          className="roleta-wheel-manivela-svg"
          width={size}
          height={size}
          viewBox={viewBox}
        >
          {customization?.slices?.map((piece, i) => {
            return (
              <g>
                <path
                  className="roleta-espaco-fatias"
                  d={getPiePath(i)}
                  fill={piece.backgroundColor}
                  stroke-width="1"
                  id={piece.position}
                />
                <text
                  x={getTextX(i)}
                  y={getTextY(i)}
                  transform={`rotate(${getTextRotation(i)}, ${getTextX(i)}, ${getTextY(i)})`}
                  dominant-baseline="middle"
                  text-anchor="middle"
                  className="roleta-texto-fatias"
                  fill={piece?.textColor}
                  fontSize="15px"
                >
                  {slices[i]?.name}
                </text>
              </g>
            );
          })}
        </svg>
      </>
    );
  };
  return (
    <RoletaStyle>
      <div class="roleta-estrutura" data-id="I176:3146;72:403">
        <Seta />
        <Manivela wheelRef={wheelRef} />
        <div class="roleta-container" data-id="I176:3146;72:404">
          <div class="roleta">{wheel()}</div>
          <div class="roleta-fundo" data-id="I176:3146;72:432"></div>
          <div
            class="roleta-borda-fora roleta-bordas"
            data-id="I176:3146;72:434"
          ></div>
          <div
            class="roleta-borda-dentro roleta-bordas"
            data-id="I176:3146;72:435"
          ></div>
          <div class="roleta-centro" data-id="I176:3146;72:437"></div>
        </div>
      </div>
    </RoletaStyle>
  );
}

const Seta = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SetaStyle
          style={{ position: "relative", zIndex: "10" }}
          width="28"
          height="23"
          viewBox="0 0 28 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.866 21.8723C14.4811 22.539 13.5189 22.539 13.134 21.8723L1.00961 0.872309C0.624716 0.205643 1.10584 -0.627689 1.87564 -0.627689L26.1244 -0.627691C26.8942 -0.627691 27.3753 0.205643 26.9904 0.872309L14.866 21.8723Z"
            fill="green"
            fill-opacity="0.74"
          />
        </SetaStyle>
      </div>
    </>
  );
};

const Manivela = ({ wheelRef }) => {
  return (
    <>
      <div
        ref={wheelRef}
        className="roleta-wheel-manivela-svg roleta-manivela-animation"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ManivelaStyle
          style={{
            position: "relative",
            zIndex: "9",
            bottom: "10px",
          }}
          width="250"
          height="251"
          viewBox="0 0 250 251"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="125"
            cy="125.372"
            r="122.5"
            stroke="yellow"
            stroke-width="5"
            stroke-linejoin="round"
            stroke-dasharray="1 28"
          />
        </ManivelaStyle>
      </div>
    </>
  );
};
