// import axios from "axios";
import AgendaService from "./AgendaService";
import instance from "./Api";
import UsuarioService from "./UsuarioService";

/**
 * @typedef {Object} Event
 * @property {string} uuid - O UUID do evento.
 * @property {string} name - O nome do evento.
 * @property {string} code - O código do evento.
 * @property {string} launchDate - A data de lançamento do evento.
 * @property {string} startDate - A data de início do evento.
 * @property {string} endDate - A data de término do evento.
 * @property {string} preEventStartDate - A data de início do pré-evento.
 * @property {string} preEventEndDate - A data de término do pré-evento.
 * @property {string} endContentDate - A data de término do conteúdo do evento.
 * @property {string} feedbackDate - A data de feedback do evento.
 * @property {string|null} tipoMatricula - O tipo de matrícula, se disponível, caso contrário, é null.
 * @property {boolean} captcha - Indica se o evento utiliza CAPTCHA.
 * @property {Client} client - As informações do cliente associado ao evento.
 * @property {string|null} logoLeft - O URL do logotipo esquerdo, se disponível, caso contrário, é null.
 * @property {string|null} logoRight - O URL do logotipo direito, se disponível, caso contrário, é null.
 * @property {string|null} presentationImage - O URL da imagem de apresentação, se disponível, caso contrário, é null.
 * @property {string} description - A descrição do evento.
 * @property {string} tipoInscricao - O tipo de inscrição do evento.
 * @property {string} descricaoTipoInscricao - A descrição do tipo de inscrição do evento.
 * @property {string} nomeMatricula - O nome da matrícula.
 * @property {string|null} domain - O domínio do evento, se disponível, caso contrário, é null.
 * @property {boolean} restrictEligibility - Indica se a elegibilidade é restrita.
 * @property {string} ageType - O tipo de idade do evento.
 * @property {string} title - O título do evento.
 * @property {string} subtitle - O subtítulo do evento.
 * @property {Interest[]} interests - As informações de interesse associadas ao evento.
 * @property {boolean} requiredInterests - Indica se os interesses são obrigatórios.
 * @property {string} version - A versão do evento.
 * @property {boolean} legalTerm - Indica se os termos legais estão incluídos.
 * @property {Message[]} messages - As mensagens associadas ao evento.
 * @property {string|null} emailTemplate - O modelo de e-mail associado ao evento, se disponível, caso contrário, é null.
 * @property {Style[]} styles - Os estilos do evento.
 * @property {number} daysOfContent - O número de dias de conteúdo do evento.
 * @property {number} numberOfRequiredInterests - O número de interesses obrigatórios.
 * @property {Field[]} fields - Os campos do evento.
 * @property {Template} template - As informações do modelo associado ao evento.
 * @property {Icons} icons - As informações dos ícones associados ao evento.
 * @property {string|null} comoFunciona - As informações sobre como funciona o evento, se disponível, caso contrário, é null.
 * @property {boolean} showLikes - Indica se os likes são exibidos.
 * @property {string|null} topLeftDesktop - O URL da imagem superior esquerda para desktop, se disponível, caso contrário, é null.
 * @property {string|null} topRightDesktop - O URL da imagem superior direita para desktop, se disponível, caso contrário, é null.
 * @property {string|null} presentationDesktop - O URL da imagem de apresentação para desktop, se disponível, caso contrário, é null.
 * @property {string} privacyPolicy - A política de privacidade do evento.
 * @property {boolean} titleVisible - Indica se o título é visível.
 * @property {boolean} showRankingGame - Indica se o ranking do jogo é exibido.
 * @property {boolean} registrationUnitMandatory - Indica se a unidade de registro é obrigatória.
 * @property {('REGULAR'|'INDIVIDUAL'|'EQUIPE')} campaignType - O tipo de campanha do evento
 * @property {number} levelUnit - O nível da unidade do evento.
 * @property {string[]} extraAuthInfo - As informações de autenticação extra do evento.
 * @property {Roulette} roulette - As informações da roleta associada ao evento.
 * @property {PersonalizationEvent} personalizationEvent - As informações de personalização do evento.
 */

/**
 * Representa um campo de formulário com suas propriedades e configurações.
 * @typedef {Object} Field
 * @property {string} uuid - Identificador único universal do campo.
 * @property {string} field - Nome do campo no sistema.
 * @property {string} label - Rótulo de exibição do campo para o usuário.
 * @property {number} order - Ordem de exibição do campo no formulário.
 * @property {boolean} required - Indica se o campo é obrigatório.
 * @property {('TEXT'|'CUSTOMIZADO')} type - Tipo do campo. Pode ser 'TEXT' ou 'CUSTOMIZADO'.
 * @property {(string|null)} mask - Máscara de formatação do campo, se houver.
 * @property {('SELECT'|null)} parse - Tipo de análise/transformação do campo. Pode ser 'SELECT' para campos de seleção.
 * @property {Array<Option>} options - Lista de opções disponíveis para o campo.
 * @property {boolean} usedAuthentication - Indica se o campo é utilizado para autenticação.
 */

/**
 * Representa uma opção de seleção para campos do tipo SELECT.
 * @typedef {Object} Option
 * @property {string} uuid - Identificador único universal da opção.
 * @property {string} text - Texto exibido para a opção.
 */
/**
 * @typedef {Object} Client
 * @property {string|null} uuid - O UUID do cliente, se disponível, caso contrário, é null.
 * @property {string|null} name - O nome do cliente, se disponível, caso contrário, é null.
 * @property {Unit[]} units - As informações das unidades associadas ao cliente.
 * @property {string|null} cnpj - O CNPJ do cliente, se disponível, caso contrário, é null.
 * @property {string|null} endereco - O endereço do cliente, se disponível, caso contrário, é null.
 * @property {string|null} telefone - O telefone do cliente, se disponível, caso contrário, é null.
 * @property {string|null} nomeContato - O nome do contato do cliente, se disponível, caso contrário, é null.
 * @property {string|null} emailContato - O e-mail do contato do cliente, se disponível, caso contrário, é null.
 * @property {string|null} observacoes - Observações adicionais sobre o cliente, se disponíveis, caso contrário, é null.
 * @property {string|null} createdAt - A data de criação do cliente, se disponível, caso contrário, é null.
 */

/**
 * @typedef {Object} Unit
 * @property {number} id - O ID da unidade.
 * @property {string} uuid - O UUID da unidade.
 * @property {string|null} parent - O UUID da unidade pai, se disponível, caso contrário, é null.
 * @property {string} name - O nome da unidade.
 * @property {boolean} active - Indica se a unidade está ativa.
 * @property {boolean} accounted - Indica se a unidade está contabilizada.
 * @property {Client} client - As informações do cliente associado à unidade.
 * @property {Unit[]} subUnits - As informações das subunidades associadas à unidade.
 * @property {string|null} selecionadoTree - Indica se a unidade está selecionada na árvore, se disponível, caso contrário, é null.
 */

/**
 * @typedef {Object} Interest
 * @property {string|null} uuid - O UUID do interesse, se disponível, caso contrário, é null.
 * @property {string|null} name - O nome do interesse, se disponível, caso contrário, é null.
 * @property {boolean} active - Indica se o interesse está ativo.
 * @property {Unit[]|null} units - As informações das unidades associadas ao interesse, se disponíveis, caso contrário, é null.
 */

/**
 * @typedef {Object} Message
 * @property {string} uuid - O UUID da mensagem.
 * @property {string} code - O código da mensagem.
 * @property {string} language - O idioma da mensagem.
 * @property {string} message - A mensagem do evento.
 * @property {null} event - O evento associado à mensagem (nulo no objeto fornecido).
 */

/**
 * @typedef {Object} Template
 * @property {string|null} uuid - O UUID do modelo, se disponível, caso contrário, é null.
 * @property {string|null} name - O nome do modelo, se disponível, caso contrário, é null.
 * @property {string|null} description - A descrição do modelo, se disponível, caso contrário, é null.
 * @property {string|null} dateCreated - A data de criação do modelo, se disponível, caso contrário, é null.
 * @property {boolean} active - Indica se o modelo está ativo.
 * @property {boolean} standard - Indica se o modelo é padrão.
 * @property {Color[]} colors - As informações das cores associadas ao modelo.
 * @property {Shadow[]} shadows - As informações das sombras associadas ao modelo.
 * @property {Client} client - As informações do cliente associado ao modelo.
 */

/**
 * @typedef {Object} Icons
 * @property {string} uuid - O UUID do conjunto de ícones.
 * @property {string} name - O nome do conjunto de ícones.
 * @property {boolean} defaultKit - Indica se é o kit de ícones padrão.
 * @property {string} description - A descrição do conjunto de ícones.
 * @property {Icon[]} icons - As informações dos ícones associados ao conjunto.
 */

/**
 * @typedef {Object} Color
 * @property {string} uuid - O UUID da cor.
 * @property {string} code - O código da cor.
 * @property {string} color - O valor da cor.
 */

/**
 * @typedef {Object} Shadow
 * @property {string} uuid - O UUID da sombra.
 * @property {string} code - O código da sombra.
 * @property {string} bgColor - A cor de fundo da sombra.
 * @property {string} boxShadow - O estilo da sombra.
 */

/**
 * @typedef {Object} Icon
 * @property {string} uuid - O UUID do ícone.
 * @property {string} dinamica - A dinâmica do ícone.
 * @property {boolean} status - O status do ícone.
 * @property {string} url - O URL do ícone.
 */

/**
 * Represents a page configuration.
 * @typedef {Object} Style
 *
 * @property {string} uuid - The unique identifier for the configuration.
 * @property {string} page - The name or type of the page (e.g., "ENTRADA").
 * @property {string} backgroundImage - The URL for the background image (for mobile or default).
 * @property {string} backgroundImageDesktop - The URL for the background image specifically for desktop views.
 * @property {string} mimeType - The MIME type of the background images (e.g., "image/png").
 * @property {string} event - An identifier related to an event associated with this configuration.
 * @property {string} titleFontSize - The font size for the title, can be a specific value or a keyword like "PADRAO".
 */

/**
 * Representa um template de roleta promocional com informações de configuração e personalização.
 * @typedef {Object} Roulette
 * @property {string} uuid - Identificador único da roleta.
 * @property {string} title - Título do template.
 * @property {string} name - Nome do template.
 * @property {string} description - Descrição do template.
 * @property {?string} presentation - Apresentação do template (pode ser nula).
 * @property {boolean} active - Indica se o template está ativo.
 * @property {number} numberOfSlices - Número de fatias da roleta.
 * @property {string} eventUuid - Identificador único do evento associado.
 * @property {Customization} customization - Informações de personalização da roleta.
 * @property {Slice[]} slices - Lista de fatias da roleta.
 * @property {string} type - Tipo do template (por exemplo, "CAMPANHA").
 * @property {boolean} showPresentation - Indica se a apresentação deve ser exibida.
 * @property {string} url - URL associada ao template.
 */

/**
 * Representa as configurações de personalização da roleta.
 * @typedef {Object} Customization
 * @property {string} logo - Logo associado à personalização.
 * @property {string} uuid - Identificador único da personalização.
 * @property {string} title - Título da personalização.
 * @property {string} subtitle - Subtítulo da personalização.
 * @property {Color[]} colors - Lista de cores para a personalização.
 * @property {Slice[]} slices - Lista de fatias personalizadas.
 * @property {string} textButton - Texto do botão para girar a roleta.
 */

/**
 * Representa uma cor utilizada na personalização.
 * @typedef {Object} Color
 * @property {string} uuid - Identificador único da cor.
 * @property {string} color - Código hexadecimal da cor.
 * @property {string} code - Código associado à cor (por exemplo, "SETA").
 */

/**
 * Representa uma fatia da roleta.
 * @typedef {Object} Slice
 * @property {string} uuid - Identificador único da fatia.
 * @property {number} position - Posição da fatia na roleta.
 * @property {string} name - Nome da fatia.
 * @property {string} description - Descrição da fatia.
 * @property {?number} probability - Probabilidade de a fatia ser selecionada (pode ser nula).
 * @property {?string} giveaway - Prêmio associado à fatia (pode ser nulo).
 * @property {string} [backgroundColor] - Cor de fundo da fatia (opcional).
 * @property {string} [textColor] - Cor do texto da fatia (opcional).
 */

/**
 * Representa as personalizações da campanha
 * @typedef {Object} PersonalizationEvent
 * @property {string} NAME
 * @property {string} ACTIVE_ROULETTE
 * @property {string} HIDE_RANKING
 * @property {string} SHOW_CERTIFICATE
 * @property {string} SHOW_DATE_SCHEDULE
 * @property {string} SHOW_OWL
 *
 */

export default class CampanhaService {
  static resquesLock = false;

  inscrever(request, callback) {
    instance
      .post(this.host() + "/api/v1/campaign/public/register", request)
      .then((response) => {
        if (response.data.authorization) {
          const usuarioService = new UsuarioService();
          usuarioService.setUsuarioCorrente(response.data.authorization);
          usuarioService.setUsuarioAvatar(
            response?.data?.authorization?.avatarDTO,
          );
          usuarioService.setUserTeamUuid(response?.data?.authorization?.team);
          //espera 300ms para enviar o token para o notificador
          setTimeout(() => {
            console.log("Enviando token para o notificador");
            //requestPermission();
          }, 300);
        }

        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  agregarEstiloRoleta(data) {
    if (data.roulette) {
      const roleta = data.roulette;
      if (
        roleta.customization &&
        roleta.customization.slices &&
        roleta.slices
      ) {
        const customization = roleta.customization;
        const slices = roleta.slices;

        for (let i = 0; i < slices.length; i++) {
          for (let j = 0; j < customization.slices.length; j++) {
            if (slices[i].position === customization.slices[j].position) {
              slices[i].backgroundColor =
                customization.slices[j].backgroundColor;
              slices[i].textColor = customization.slices[j].textColor;
            }
          }
        }
      }
    }
  }

  definirCampanha(codigo, params, callback, limparAgenda = true) {
    let usuarioService = new UsuarioService();
    //params é um objetos de params que podem ser passados na url
    let paramsString = this.getDashboardStyle(params);

    instance
      .get(
        this.host() + "/api/v1/campaign/public/" + codigo + "?" + paramsString,
      )
      .then((response) => {
        const { data } = response;
        this.agregarEstiloRoleta(data);
        window.localStorage.setItem("campanha", JSON.stringify(data));
        window.localStorage.setItem("template", JSON.stringify(data.template));
        window.localStorage.setItem("icone", JSON.stringify(data.icons));
        if (limparAgenda) {
          window.localStorage.removeItem("agenda");
          window.localStorage.removeItem("agenda-global");
          window.localStorage.removeItem("validade-cache-agenda");
        }

        let usuarioCorrente = usuarioService.usuarioCorrente();
        if (
          usuarioCorrente &&
          usuarioCorrente.campaignCode &&
          usuarioCorrente.campaignCode !== codigo
        ) {
          usuarioService.logout(() => {});
        }
        callback(null, data);
      })
      .catch((error) => {
        window.localStorage.removeItem("campanha");
        window.localStorage.removeItem("template");
        window.localStorage.removeItem("icone");
        window.localStorage.removeItem("agenda");
        window.localStorage.removeItem("agenda-global");
        window.localStorage.removeItem("validade-cache-agenda");
        callback(error, null);
      });
  }

  getDashboardStyle(params) {
    const storageKey = "dashboardStyles";
    const storedStyle = localStorage.getItem(storageKey);
    if (params?.dashboardStyles) {
      localStorage.setItem(storageKey, JSON.stringify(params.dashboardStyles));
      return `dashboardStyles=${!!params.dashboardStyles}`;
    }

    if (storedStyle) {
      return `dashboardStyles=${!!JSON.stringify(storedStyle)}`;
    }

    return "";
  }

  contadorCampanha(callback) {
    let newDate = new Date();
    window.localStorage.setItem("date-request-time", newDate);
    if (this.timeToNextRequestValido(newDate)) {
      callback(null, this.obterCheckStartDaCampanha());
    } else {
      instance
        .get(
          this.host() +
            "/api/v1/campaign/" +
            this.campanhaCorrente().uuid +
            "/checkStart",
        )
        .then((response) => {
          window.localStorage.setItem(
            "check-start-campanha",
            JSON.stringify(response.data.days),
          );

          window.localStorage.setItem(
            "time-to-next-request",
            newDate.getTime() + response.data.timeToNextRequest,
          );
          callback(null, response.data.days);
        })
        .catch((error) => {
          this.removeCheckStartETimeToNextRequestCache();
          callback(error, null);
        });
    }
  }

  removeCheckStartETimeToNextRequestCache() {
    window.localStorage.removeItem("check-start-campanha");
    window.localStorage.removeItem("time-to-next-request");
    window.localStorage.removeItem("date-request-time");
  }

  obterCheckStartDaCampanha() {
    let checkStart = window.localStorage.getItem("check-start-campanha");
    if (checkStart) {
      return JSON.parse(checkStart);
    } else {
      return null;
    }
  }

  timeToNextRequestValido(newDate) {
    let timeToNextRequest = window.localStorage.getItem("time-to-next-request");
    if (timeToNextRequest) {
      return parseInt(timeToNextRequest) > newDate.getTime();
    } else {
      return false;
    }
  }

  obterCampanhas(callback) {
    instance
      .get(this.host() + "/api/v1/campaign/public/public-campaigns")
      .then((response) => {
        callback(null, response.data);
      })
      .catch((err) => {
        callback(err, null);
      });
  }

  validarRecaptcha(token, callback) {
    const request = { response: token };
    instance
      .post(this.host() + "/api/v1/captcha/verify", request)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  /**
   * Faz uma chamada para /api/v1/campaign/{uuid}/hash.
   *
   * Verifica se o hash da campanha é diferente do que esta salvo no cache.
   */
  sincronizarCampanha() {
    return new Promise((resolve, reject) => {
      const campanhaCorrente = this.campanhaCorrente();
      if (!campanhaCorrente) {
        resolve(false);
      }
      instance
        .get(
          this.host() + "/api/v1/campaign/" + campanhaCorrente.uuid + "/hash",
        )
        .then((response) => {
          if (response.data.hash !== campanhaCorrente.hash) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /** @returns {Event} */
  campanhaCorrente() {
    let valorStorage = window.localStorage.getItem("campanha");
    if (valorStorage) {
      try {
        const campanha = JSON.parse(valorStorage);
        campanha.registrationUnitMandatory = campanha.levelUnit > 0;
        return campanha;
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * @returns {Roulette}
   * */
  getRoleta() {
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.roulette;
    }
  }

  templateCorrente() {
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.template;
    }
  }

  iconeCorrente() {
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.icons;
    }
  }

  i18nCorrente() {
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.messages;
    }
  }

  gestoresCampanha() {
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.gestores;
    }
  }

  removeLocalStorageSeCampanhasForemDiferentes(code) {
    let campanha = this.campanhaCorrente();
    if (campanha && campanha.code !== code) {
      return this.clearAll();
    }
    return null;
  }

  verificaCodigoCampanhaPresenteParamsOuSubdominio(paramsCodigoCampanha) {
    const subdominioCodigoCampanha = window.location.hostname.split(".")[0];
    if (!paramsCodigoCampanha && subdominioCodigoCampanha) {
      return subdominioCodigoCampanha;
    }
    return paramsCodigoCampanha;
  }

  isDesktopModel() {
    return window.screen.width > 700;
  }

  /*
		Remove todas as chaves presentes no localstorage do sistema
	*/
  clearAll() {
    window.localStorage.clear();
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }

  hashTimeout() {
    return parseInt(process.env.REACT_APP_HASH_TIMEOUT);
  }

  definirCampanhaPromise(codigo) {
    return new Promise((resolve, reject) => {
      this.definirCampanha(
        codigo,
        {},
        (error, campanha) => {
          if (error) {
            reject(error);
          } else {
            resolve(campanha);
          }
        },
        false,
      );
    });
  }

  async useSincronizarCampanha() {
    try {
      if (CampanhaService.resquesLock) {
        return;
      }

      const campanhaCorrente = this.campanhaCorrente();
      if (!campanhaCorrente) {
        return;
      }

      const lastCheck = localStorage.getItem("last-campanha-check");
      const now = new Date().getTime();

      if (lastCheck && now - parseInt(lastCheck) < this.hashTimeout()) {
        return;
      }

      localStorage.setItem("last-campanha-check", now.toString());
      CampanhaService.resquesLock = true;
      const alterada = await this.sincronizarCampanha();
      if (alterada) {
        const agendaService = new AgendaService();

        const [campanha, agenda] = await Promise.all([
          this.definirCampanhaPromise(campanhaCorrente?.code),
          agendaService.agendaCorrentePromise(),
        ]);

        if (campanha && agenda) {
          agendaService.invalidateCacheAndReload();
        }
      }
      CampanhaService.resquesLock = false;
    } catch (e) {
      console.error("Error: " + e);
    }
  }

  getUnidades() {
    return this.campanhaCorrente().client.units;
  }

  getBeginDate() {
    let dtStart = this.campanhaCorrente()?.startDate?.split(" ");
    if (dtStart) {
      let dateStart = dtStart[0].split("/");
      let hourStart = dtStart[1].split(":");
      return new Date(
        dateStart[2],
        dateStart[1] - 1,
        dateStart[0],
        hourStart[0],
        hourStart[1],
        hourStart[2],
      );
    }
  }

  getInformacaoParticipante() {
    const campanhaCorrente = this.campanhaCorrente();
    const usuarioService = new UsuarioService();
    const participanteCorrente = usuarioService.usuarioCorrente();

    if (campanhaCorrente?.personalizationEvent?.NONE_INFO_PART) {
      return "";
    }

    if (
      campanhaCorrente?.personalizationEvent?.NAME &&
      participanteCorrente?.name
    ) {
      return this.getNomeFormatado(participanteCorrente.name);
    }

    if (
      campanhaCorrente?.personalizationEvent?.ENROLLMENT &&
      participanteCorrente?.enrollment
    ) {
      return participanteCorrente.enrollment;
    }

    if (
      campanhaCorrente?.personalizationEvent?.EMAIL &&
      participanteCorrente?.email
    ) {
      return participanteCorrente.email;
    }

    return (
      this.getNomeFormatado(participanteCorrente?.name) ||
      participanteCorrente?.enrollment ||
      participanteCorrente?.email ||
      "Sem nome, matrícula ou email cadastrados"
    );
  }

  getNomeFormatado(nomeParticipante) {
    if (!nomeParticipante) return null;
    const nomeSeparado = nomeParticipante.split(" ");
    if (nomeSeparado[0] === nomeSeparado[nomeSeparado.length - 1]) {
      return nomeSeparado[0];
    }
    return `${nomeSeparado[0]} ${nomeSeparado[nomeSeparado.length - 1]}`;
  }

  naoMostrarInfoParticipante() {
    const campanhaCorrente = this.campanhaCorrente();
    return campanhaCorrente?.personalizationEvent?.NONE_INFO_PART;
  }

  isDesktopModelByElement(element) {
    if (element) {
      var rect = element.getBoundingClientRect();
      return rect.width > 700;
    }
    return false;
  }
}
