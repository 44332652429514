import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import CampanhaService from "../../../services/CampanhaService";
import TemaService from "../../../services/TemaService";
import { RegisterForm } from "../../../pages/authInscricao/style";
import PasswordService from "../../../services/PasswordService";
import InputsCustomizadosService from "../../../services/InputsCustomizadosService";
import WeexFactory from "../../WeexInputs/WeexFactory";
import GerarSelectUnidadesTree from "../input/GerarSelectUnidadesTree";
import WeexTermsConditions from "../../WeexInputs/WeexTermsConditions";
import SecundarioBtn from "../../buttonPWA2.0/SecundarioBtn";
import WeexModal from "../../weexModais/WeexModal";
import UsuarioService from "../../../services/UsuarioService";
import WeexSelect from "../../WeexInputs/WeexSelect";

const usuarioService = new UsuarioService();

function CustomRegisterCampaign({ modoLogin }) {
  const [request, setRequest] = useState({
    firstName: null,
    email: null,
    lastName: null,
    password1: null,
    password2: null,
    birthday: null,
    unit: null,
    subUnit: null,
    level3: null,
    age: 18,
  });
  const [accept, setAccept] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [erro, setErro] = useState(null);
  const [processando, setProcessando] = useState(false);
  const navigate = useNavigate();

  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const service = new CampanhaService();
  const campanha = service?.campanhaCorrente();
  const inputsCustomizadosService = new InputsCustomizadosService();

  const coletaIdade = campanha.ageType;
  const expoeDataNascimento = coletaIdade === "DATE";
  const expoeIdade = coletaIdade === "AGE";
  const expoeUnidades =
    campanha?.client &&
    campanha?.client?.units &&
    campanha?.client?.units?.length > 0
      ? true
      : false;

  const campaignType = campanha?.campaignType;
  const isCampaignTypeEquipe = campaignType === "EQUIPE";

  // const minScore = process.env.REACT_APP_RECAPTCHA_MIN_SCORE;
  const recaptchaToken = process.env.REACT_APP_RECAPTCHA_SITE_TOKEN;

  useEffect(() => {
    if (service.campanhaCorrente().captcha) {
      // loadReCaptcha(recaptchaToken, () => {});
    } else {
      setCaptcha(true);
    }
  }, [recaptchaToken, service]);

  const changeDataFormat = (data) => {
    if (!data) return null;

    // Converte de DD/MM/YYYY para MM/DD/YYYY para compatibilidade com o construtor de Date
    const parts = data.split("/");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const formattedDate = `${month}/${day}/${year}`;

      // Cria um objeto Date e verifica se é válido
      const dateObject = new Date(formattedDate);
      if (!isNaN(dateObject.getTime())) {
        return `${day}/${month}/${year}`;
      }
    }
    return null;
  };

  const onChange = (event) => {
    if (event != null) {
      setRequest((prevRequest) => {
        const newRequest = { ...prevRequest };
        newRequest[event.target.name] = event.target.value;

        // Resetando outros campos conforme necessário
        if (event.target.name === "unit") {
          newRequest["subUnit"] = null;
          newRequest["level3"] = null;
        }

        if (event.target.name === "subUnit") {
          newRequest["level3"] = null;
        }

        return newRequest;
      });
    }
  };

  const onChangeDataNascimento = (value) => {
    if (value != null) {
      let req = request;
      req["birthday"] = changeDataFormat(value);
      setRequest(req);
    }
  };

  const onChangeDate = (value, label) => {
    if (value != null) {
      let req = request;
      req[label] = changeDataFormat(value);
      setRequest(req);
    }
  };

  const onChangeText = (value, field) => {
    if (value != null) {
      setRequest((prevRequest) => {
        const newRequest = { ...prevRequest };
        newRequest[field.uuid] = value;
        return newRequest;
      });
    }
  };

  const onChangeAccept = (event) => {
    setAccept(event.target.checked);
  };

  const registro = (event) => {
    if (!campanha) {
      alert("Sem campanha");
    }

    event.preventDefault();
    if (processando) {
      return;
    }

    setProcessando(true);
    if (
      campanha.registrationUnitMandatory &&
      campanha.client &&
      campanha.client.units &&
      campanha.client.units.length > 0
    ) {
      if (!request.unit || request.unit === null) {
        setErro({
          titulo: i18n.message(
            "registro.erro.campo.invalido",
            "Campo inválido",
          ),
          mensagem: i18n.message(
            "registro.erro.unidade",
            "Você deve escolher uma unidade",
          ),
        });
        setProcessando(false);
        return;
      }
      let unidade = campanha?.client?.units?.find(
        (unidade) => unidade?.uuid === request.unit,
      );
      if (unidade && unidade?.subUnits?.length > 0) {
        if (!request.subUnit || request.subUnit === null) {
          setErro({
            titulo: i18n.message(
              "registro.erro.campo.invalido",
              "Campo inválido",
            ),
            mensagem: i18n.message(
              "registro.erro.subunidade",
              "Você deve escolher uma sub-unidade",
            ),
          });
          setProcessando(false);
          return;
        }
        let subUnit = unidade?.subUnits?.find(
          (uni) => uni.uuid === request.subUnit,
        );
        if (subUnit && subUnit?.subUnits?.length > 0) {
          if (!request.level3 || request.level3 === null) {
            setErro({
              titulo: i18n.message(
                "registro.erro.campo.invalido",
                "Campo inválido",
              ),
              mensagem: i18n.message(
                "registro.erro.subunidade",
                "Você deve escolher uma sub-sub-unidade",
              ),
            });
            setProcessando(false);
            return;
          }
        }
      }
    }
    if (!accept) {
      setErro({
        titulo: i18n.message(
          "registro.erro.legal.titulo",
          "Aceite nossas condições",
        ),
        mensagem: i18n.message(
          "registro.erro.legal.mensagem",
          "Você não aceitou os termos e condições do Weex.",
        ),
      });
      setProcessando(false);
      return;
    }
    if (
      expoeDataNascimento &&
      inputsCustomizadosService.possuiCampo("DATA_NASCIMENTO")
    ) {
      if (request?.birthday?.length !== 10) {
        setErro({
          titulo: i18n.message(
            "registro.erro.nascimento.titulo",
            "Data de nascimento inválida",
          ),
          mensagem: i18n.message(
            "registro.erro.nascimento.mensagem",
            "A data de nascimento que você digitou é inválida.",
          ),
        });
        setProcessando(false);
        return;
      }
      let camposData = request.birthday.split("/");
      if (
        new Date() <
        new Date(
          parseInt(camposData[2]) + 13,
          parseInt(camposData[1]) - 1,
          camposData[0],
        )
      ) {
        setErro({
          titulo: i18n.message(
            "registro.erro.nascimento.titulo",
            "Data de nascimento inválida",
          ),
          mensagem: "Idade menor que 13.",
        });
        setProcessando(false);
        return;
      }
      if (camposData[1] > 12 || camposData[1] < 1) {
        setErro({
          titulo: i18n.message(
            "registro.erro.nascimento.titulo",
            "Data de nascimento inválida",
          ),
          mensagem:
            i18n.message(
              "registro.erro.nascimentomes.mensagem.parte1",
              "Mês ",
            ) +
            camposData[1] +
            i18n.message(
              "registro.erro.nascimentomes.mensagem.parte2",
              " é inválido, meses válidos entre 1 e 12 inclusos esses valores.",
            ),
        });
        setProcessando(false);
        return;
      }
      let ultimoDiaMes = new Date(camposData[2], camposData[1], 0).getDate();
      if (camposData[0] > ultimoDiaMes || camposData[0] < 1) {
        setErro({
          titulo: i18n.message(
            "registro.erro.nascimento.titulo",
            "Data de nascimento inválida",
          ),
          mensagem:
            i18n.message("registro.erro.nascimentodia.mensagem.parte1", "Dia") +
            " " +
            camposData[0] +
            " " +
            i18n.message(
              "registro.erro.nascimentodia.mensagem.parte2",
              "é inválido, válidos para o mês",
            ) +
            " " +
            camposData[1] +
            "/" +
            camposData[2] +
            " " +
            i18n.message(
              "registro.erro.nascimentodia.mensagem.parte3",
              " entre 1 e",
            ) +
            " " +
            ultimoDiaMes +
            " " +
            i18n.message(
              "registro.erro.nascimentodia.mensagem.parte4",
              "inclusos esses valores.",
            ),
        });
        setProcessando(false);
        return;
      }
    }

    if (expoeIdade) {
      if (request.age < 13) {
        setErro({
          titulo: i18n.message("registro.erro.idade.titulo", "Idade inválida"),
          mensagem: i18n.message(
            "registro.erro.idade.menor13",
            "Menor que 13.",
          ),
        });
        setProcessando(false);
        return;
      }
    }

    if (inputsCustomizadosService.possuiCampo("SENHA")) {
      const passowordService = new PasswordService();
      const passwordWeaknesses = passowordService.getPasswordWeaknesses(
        request.password1,
        i18n,
      );
      if (passwordWeaknesses.length > 0) {
        setErro({
          titulo: i18n.message(
            "registro.erro.senhas.fraca.titulo",
            "A senha é fraca, sua senha deve ter",
          ),
          mensagem: passwordWeaknesses,
        });
        setProcessando(false);
        return;
      }

      if (request.password1 !== request.password2) {
        setErro({
          titulo: i18n.message(
            "registro.erro.senhas.titulo",
            "Senhas não conferem",
          ),
          mensagem: i18n.message(
            "registro.erro.senhas.mensagem",
            "As senhas que você digitou não conferem.",
          ),
        });
        setProcessando(false);
        return;
      }
    }

    const fieldsCustomizado = campanha.fields.filter(
      (f) => f.field === "CUSTOMIZADO",
    );

    const getValue = (f) => {
      if (f.parse === "BOOLEAN") {
        return request[f.uuid] ? "true" : "false";
      }
      return request[f.uuid];
    };

    //busca campos customizados no objeto request
    const camposCustomizados = fieldsCustomizado.map((f) => {
      return {
        code: f.uuid,
        value: getValue(f),
      };
    });

    const extraInfo = [];

    const convite = usuarioService.getConvite();
    console.log("convite", convite);
    if (convite) {
      extraInfo.push({ code: "INVITE", value: convite });
    }
    console.log("extraInfo", extraInfo);

    const inscricao = {
      birthDate: changeDataFormat(request.birthday),
      enrollment: request.enrollment,
      email: request.email,
      firstName: request.firstName,
      lastName: request.lastName,
      password: request.password1,
      campaign: campanha.code,
      unit: request.unit,
      level3: request.level3,
      subUnit: request.subUnit,
      age: request.age,
      customFields: camposCustomizados,
      extraInfo: extraInfo,
    };
    service.inscrever(inscricao, (erro, sucesso) => {
      if (erro) {
        setProcessando(false);
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            titulo: i18n.message(
              "registro.erro.inscricao.titulo",
              "Erro na inscrição",
            ),
            mensagem: erro.response.data.message,
          });
        } else {
          setErro({
            titulo: i18n.message(
              "registro.erro.inscricao.titulo",
              "Erro na inscrição",
            ),
            mensagem: i18n.message(
              "geral.erro.network.mensagem",
              "Falha ao tentar se registrar, entre em contato com o suporte ou tente novamente mais tarde",
            ),
          });
        }
        return;
      }
      if (sucesso) {
        const temaService = new TemaService();
        temaService.removerTema();
        setProcessando(false);
        if (sucesso.authorization) {
          dispatch({ type: "usuario", payload: true });
          dispatch({ type: "usuariologado", payload: sucesso });
          dispatch({ type: "userTeamUuid", payload: sucesso.team });
          dispatch({
            type: "usuarioAvatar",
            payload: sucesso?.avatarDTO ? true : false,
          });
          dispatch({ type: "avatar", payload: sucesso?.avatarDTO });
          service.contadorCampanha((erro, sucesso) => {
            if (erro) {
              setErro({
                titulo: i18n.message(
                  "atividades.erro.dias.habilitados",
                  "Erro obter lista de dias habilitados",
                ),
              });
              return;
            }
            if (sucesso) {
              if (sucesso !== null) {
                dispatch({ type: "campanhaComecou", payload: false });
                const adminOrGestor =
                  usuarioService.isAdmin() || usuarioService.isGestor();
                if (
                  isCampaignTypeEquipe &&
                  !usuarioService.usuarioPossuiEquipe() &&
                  !adminOrGestor
                ) {
                  navigate("/selectTeam");
                  return;
                }
                navigate("/home");
              }
            }
          });
        } else {
          setErro({
            titulo: i18n.message(
              "registro.erro.aposInscricao.titulo",
              "Sobre sua inscrição",
            ),
            mensagem: sucesso.message,
          });
        }
      }
    });
  };

  // const verifyCallback = (recaptchaToken) => {
  //   service.validarRecaptcha(recaptchaToken, (erro, sucesso) => {
  //     if (sucesso) {
  //       if (
  //         sucesso.success &&
  //         parseFloat(sucesso.score) > parseFloat(minScore)
  //       ) {
  //         setCaptcha(true);
  //       } else {
  //         setCaptcha(false);
  //         setErro({
  //           titulo: i18n.message("registro.erro.recaptcha.titulo", "Recaptcha"),
  //           mensagem: i18n.message(
  //             "registro.erro.recaptcha.mensagem",
  //             "A verificação do recaptcha falhou"
  //           ),
  //         });
  //       }
  //     } else if (erro) {
  //       if (erro.response && erro.response.data && erro.response.data.message) {
  //         setErro({
  //           titulo: i18n.message(
  //             "registro.erro.inscricao.titulo",
  //             "Erro na inscrição"
  //           ),
  //           mensagem: erro.response.data.message,
  //         });
  //       } else {
  //         setErro({
  //           titulo: i18n.message(
  //             "registro.erro.inscricao.titulo",
  //             "Erro na inscrição"
  //           ),
  //           mensagem: i18n.message(
  //             "geral.erro.network.mensagem",
  //             "Falha ao tentar se registrar, entre em contato com o suporte ou tente novamente mais tarde"
  //           ),
  //         });
  //       }
  //       return;
  //     }
  //   });
  // };

  if (!campanha || !campanha.tipoInscricao) {
    service.clearAll();
    if (!window.location.href.endsWith("campaign")) {
      window.location = "/campaign";
    }
  }

  const callbackErr = () => {
    return setErro();
  };

  const fieldsSorted = campanha.fields;
  fieldsSorted.sort(function (f1, f2) {
    if (f1.order > f2.order) {
      return 1;
    }
    if (f1.order < f2.order) {
      return -1;
    }
    return 0;
  });

  const renderCustomInputs = () => {
    const fieldsComDiv = [];
    let grupo = [];

    for (let i = 0; i < fieldsSorted.length; i++) {
      grupo.push(fieldsSorted[i]);
      if (grupo.length === 2 || i === fieldsSorted.length - 1) {
        const div = (
          <fieldset key={`div-${i}`} className="registerForm__fieldset">
            {grupo.map((f, index) => (
              <>{renderizaCampo(f, index)}</>
            ))}
            {hasCampoVazio(fieldsSorted, i) ? <div></div> : null}
          </fieldset>
        );

        fieldsComDiv.push(div);
        grupo = [];
      }
    }
    return fieldsComDiv;
  };

  const hasCampoVazio = (fields, index) => {
    //verifica se o valor dos campos é impar
    if (fields.length % 2 === 0) {
      return false;
    }
    //verifica se o index é o ultimo campo
    if (index === fields.length - 1) {
      return true;
    }
  };

  const renderizaCampo = (f, index) => {
    let retorno = "";
    if (f.field === "NOME") {
      return (
        <WeexFactory
          key={index}
          id="firstName"
          type={f.type}
          name="firstName"
          placeholder={f.label}
          onChange={onChange}
          required={f.required}
          ariaLabel={i18n.message(
            "aria.label.campo.first.name",
            "Insira seu nome",
          )}
        />
      );
    } else if (f.field === "SOBRENOME") {
      return (
        <WeexFactory
          key={index}
          id="lastName"
          type={f.type}
          name="lastName"
          placeholder={f.label}
          onChange={onChange}
          required={f.required}
          ariaLabel={i18n.message(
            "aria.label.campo.last.name",
            "Insira seu sobrenome",
          )}
        />
      );
    } else if (f.field === "EMAIL") {
      let required = f.required;
      if (!inputsCustomizadosService.possuiCampo("MATRICULA")) {
        required = true;
      }
      return (
        <WeexFactory
          key={index}
          id="email"
          type={f.type}
          name="email"
          placeholder={f.label}
          onChange={onChange}
          required={required}
          ariaLabel={i18n.message("aria.label.campo.email", "Insira seu email")}
        />
      );
    } else if (f.field === "SENHA") {
      return (
        <>
          <WeexFactory
            id="password"
            key={index}
            type="strongPassword"
            name="password1"
            placeholder={f.label}
            onChange={onChange}
            required={f.required}
            ariaLabel={i18n.message(
              "aria.label.campo.password",
              "Insira uma senha",
            )}
          />
          <WeexFactory
            id="password2"
            type="password"
            autoComplete={"false"}
            name="password2"
            placeholder={i18n.message(
              "registro.campo.senha2",
              "Confirme sua senha",
            )}
            onChange={onChange}
            required={f.required}
            ariaLabel={i18n.message(
              "aria.label.campo.password.confirm",
              "Confirme sua senha",
            )}
            strong={false}
          />
        </>
      );
    } else if (f.field === "DATA_NASCIMENTO") {
      return (
        <WeexFactory
          id="birthday"
          key={index}
          type={f.type}
          name="birthday"
          placeholder={f.label}
          onChange={onChangeDataNascimento}
          required={f.required}
          legenda=""
          ariaLabel={i18n.message(
            "aria.label.campo.bithday",
            "Insira sua data de nascimento",
          )}
          label={i18n.message(
            "aria.label.campo.bithday",
            "Insira sua data de nascimento",
          )}
        />
      );
    } else if (f.field === "IDADE") {
      return (
        <WeexFactory
          id="numbersList"
          key={index}
          type="numbersList"
          name="age"
          placeholder={f.label}
          onChange={onChange}
          required={f.required}
          ariaLabel={i18n.message("aria.label.campo.idade", "Insira sua idade")}
        />
      );
    } else if (f.field === "MATRICULA") {
      let required = true;
      return (
        <WeexFactory
          id="enrollment"
          key={index}
          type={f.type}
          name="enrollment"
          placeholder={f.label}
          onChange={onChange}
          required={required}
          ariaLabel={i18n.message(
            "aria.label.campo.enrollment",
            "Insira sua matrícula",
          )}
        />
      );
    } else if (f.field === "CUSTOMIZADO") {
      return gerarCampoCustomizado(f, index);
    }
    return retorno;
  };

  const gerarCampoCustomizado = (f, index) => {
    //TEXT,
    //NUMBER,
    //BOOLEAN,
    //DATE,
    //SELECT
    switch (f.parse) {
      case "TEXT":
        return (
          <WeexFactory
            key={index}
            id={f.uuid}
            type="mask"
            name={f.uuid}
            placeholder={f.label}
            required={f.required}
            ariaLabel={f.label}
            onChange={(e) => onChangeText(e, f)}
            mask={f.mask}
          />
        );
      case "NUMBER":
        return (
          <WeexFactory
            id="numbersList"
            key={index}
            type={f.parse}
            name={f.uuid}
            placeholder={f.label}
            required={f.required}
            ariaLabel={f.label}
            onChange={onChange}
          />
        );
      case "BOOLEAN":
        return (
          <WeexFactory
            id={f.uuid}
            key={index}
            type={f.parse}
            name={f.uuid}
            placeholder={f.label}
            legenda=""
            ariaLabel={f.label}
            label={f.label}
            onChange={onChange}
            required={false}
          />
        );
      case "DATE":
        return (
          <WeexFactory
            id={f.uuid}
            key={index}
            type={f.parse}
            name={f.uuid}
            placeholder={f.label}
            required={f.required}
            legenda=""
            ariaLabel={f.label}
            label={f.label}
            onChange={(e) => onChangeDate(e, f.uuid)}
          />
        );
      case "SELECT":
        if (f.options?.length > 0) {
          return (
            <>
              <WeexSelect
                id={f.uuid}
                name={f.uuid}
                label={f.label}
                required={f.required}
                onChange={onChange}
                value={request[f.uuid] || ""}
              >
                <option value="">
                  {i18n.message("select.customizado", "Selecione uma opção")}
                </option>
                {f.options.map((opcao) => {
                  return (
                    <option value={opcao.uuid} key={opcao.uuid}>
                      {opcao.text}
                    </option>
                  );
                })}
              </WeexSelect>
            </>
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <RegisterForm onSubmit={registro}>
      {erro != null ? (
        <WeexModal
          fecharModalCallback={callbackErr}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}

      {renderCustomInputs()}

      {campanha?.registrationUnitMandatory && expoeUnidades ? (
        <>
          <GerarSelectUnidadesTree
            campanha={campanha}
            i18n={i18n}
            onChange={onChange}
            page="INSCRICAO"
          ></GerarSelectUnidadesTree>
        </>
      ) : (
        ""
      )}

      <WeexTermsConditions
        onChange={onChangeAccept}
        name="aceito"
        ariaLabel={i18n.message(
          "aria.label.campo.registro.legal.termos",
          " Aceitar termos e condições ",
        )}
      />
      <fieldset className="registerForm__fieldset--register">
        <SecundarioBtn
          type="submit"
          disabledOnClick={false}
          disabled={accept || captcha ? false : true}
          nome={
            processando
              ? i18n.message("geral.aguarde", "AGUARDE...")
              : i18n.message("registro.criar", "CADASTRAR")
          }
        />
        <SecundarioBtn
          disabled={false}
          disabledOnClick={false}
          nome={i18n.message("registro.possuiCadastro", "Já possuo cadastro")}
          funcao={modoLogin}
        />
      </fieldset>
    </RegisterForm>
  );
}

export default CustomRegisterCampaign;
