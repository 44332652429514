import React from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import MissaoCumpridaModal from "../comps/weexModais/MissaoCumpridaModal";
import AcceptLegalTerm from "../pages/AcceptLegalTerm/AcceptLegalTerm";
import AcerteTempoContainer from "../pages/acerteTempo/AcerteTempoContainer";
import Atividades from "../pages/Atividades/Atividades";
import AtivarParticipante from "../pages/authInscricao/AtivarParticipante";
import RegisterSelector from "../pages/authInscricao/inscricao/RegisterSelector";
import LoginRegister from "../pages/authInscricao/login/LoginRegister";
import ChangePassword from "../pages/authInscricao/Password/ChangePassword";
import ResetPassword from "../pages/authInscricao/Password/ResetPassword";
import CampanhaRankings from "../pages/campanhaRanking/CampanhaRankings";
import CampanhaRankingsPorEquipe from "../pages/campanhaRanking/CampanhaRankingsPorEquipe";
import Certificado from "../pages/certificado/Certificado";
import Clean from "../pages/cleanLocalStorageCache/Clean";
import ComoFunciona from "../pages/ComoFunciona/ComoFunciona";
import Convite from "../pages/convite/Convite";
import Poll from "../pages/enquete/Poll";
import Extra from "../pages/extra/Extra";
import FatoFake from "../pages/fatoFake/FatoFake";
import Feedback from "../pages/Feedback/Feedback";
import CallbackGame from "../pages/game/CallbackGame";
import Game from "../pages/game/Game";
import GameRankings from "../pages/GameRankings/GameRankings";
import Home from "../pages/Home/Home";
import Interesses from "../pages/interesses/Interesses";
import Mural from "../pages/mural/Mural";
import Notificacao from "../pages/notificacao/Notificacao";
import NovosHabitos from "../pages/NovosHabitos/NovosHabitos";
import AvaliacaoDinamicaPresencial from "../pages/presencial/AvaliacaoDinamicaPresencial";
import LeitorQrCodeDinamicaPresencial from "../pages/presencial/LeitorQrCodeDinamicaPresencial";
import Presencial from "../pages/presencial/Presencial";
import RoletaHistorico from "../pages/roletaHistorico/RoletaHistorico";
import SelecaoDeCampanhaAutomatica from "../pages/SelecaoCampanha/SelecaoDeCampanhaAutomatica";
import SeletorCampanha from "../pages/SelecaoCampanha/SeletorCampanha";
import SelectTeam from "../pages/team/SelectTeam";
import Trofeus from "../pages/Trofeus/Trofeus";
import Version from "../pages/version/Version";
import MensagemVideo from "../pages/Videos/MensagemVideo";
import Videos from "../pages/Videos/Videos";

function WeexRoutes() {
  const dispatch = useDispatch();

  /**
   * @deprecated - Será removido com o andamento do desenvolvimento do PWA 3.0
   */
  window.addEventListener("resize", function () {
    if (window.innerWidth > 700) {
      dispatch({ type: "isDesktopMode", payload: true });
    } else {
      dispatch({ type: "isDesktopMode", payload: false });
    }
  });

  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/help" element={<ComoFunciona />} />
      <Route path="/clean" element={<Clean />} />
      <Route path="/select" element={<SeletorCampanha />} />
      <Route path="/version" element={<Version />} />
      <Route path="/accept" element={<AcceptLegalTerm />} />
      <Route
        path="/campaign/:code?"
        element={<SelecaoDeCampanhaAutomatica />}
      />
      <Route path="/invite/:codigo" element={<Convite />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/passwordReset" element={<ChangePassword />} />
      <Route
        path="/activateSubscription/:code"
        element={<AtivarParticipante />}
      />
      <Route path="/interests" element={<Interesses />} />
      <Route path="/atividades" element={<Atividades />} />
      <Route path="/fatoFake/:codigo/:codigoAtividade" element={<FatoFake />} />
      <Route path="/video/:codigo/:codigoAtividade" element={<Videos />} />
      <Route path="/mural/:codigo/:codigoAtividade" element={<Mural />} />
      <Route path="/poll/:codigo/:codigoAtividade" element={<Poll />} />
      <Route
        path="/video-message/:codMensagemDeVideo"
        element={<MensagemVideo />}
      />
      <Route path="/game/:codigo/:codigoAtividade" element={<Game />} />
      <Route path="/extra/:codigo/:codigoAtividade" element={<Extra />} />
      <Route
        path="/presencial/:codigo/:codigoAtividade"
        element={<Presencial />}
      />
      <Route
        path="/presencialQrcode/:codigo/:codigoAtividade"
        element={<LeitorQrCodeDinamicaPresencial />}
      />
      <Route
        path="/presencialAvaliar/:codigo/:codigoAtividade"
        element={<AvaliacaoDinamicaPresencial />}
      />
      <Route
        path="/newHabit/:codigo/:codigoAtividade"
        element={<NovosHabitos />}
      />
      <Route
        path="/acerteTempo/:codigo/:codigoAtividade"
        element={<AcerteTempoContainer />}
      />
      <Route path="/trophy" element={<Trofeus />} />
      <Route
        path="/missionFinished/:codigoAgenda/:atividade/:codigoAtividade"
        element={<MissaoCumpridaModal />}
      />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/callbackGame/:codigo" element={<CallbackGame />} />
      <Route path="/registerSelector" element={<RegisterSelector />} />
      <Route path="/gameRankings" element={<GameRankings />} />
      <Route path="/campanhaRanking" element={<CampanhaRankings />} />
      <Route
        path="/campanhaRankingPorEquipe"
        element={<CampanhaRankingsPorEquipe />}
      />
      <Route path="/selectTeam" element={<SelectTeam />} />
      <Route path="/" element={<LoginRegister />} />
      <Route path="/certificado" element={<Certificado />} />
      <Route path="/rouletteHistory" element={<RoletaHistorico />} />
      <Route path="/notificacoes" element={<Notificacao />} />
    </Routes>
  );
}

export default WeexRoutes;
