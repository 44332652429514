// import { initializeApp } from "firebase/app";
// import {
//   deleteToken,
//   getMessaging,
//   getToken,
//   onMessage,
//   isSupported,
// } from "firebase/messaging";
// import FirebaseService from "./services/FirebaseService";
// import UsuarioService from "./services/UsuarioService";
//
// // Configuração do Firebase
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };
// // Chave VAPID (opcional, para web push)
//
// const vapidKey = process.env.REACT_APP_VAPID_KEY;
//
// const app = initializeApp(firebaseConfig);
//
// export const getMessagingInstance = async () => {
//   const supported = await isSupported();
//   if (!supported) {
//     throw new Error("Firebase Cloud Messaging não é suportado neste ambiente.");
//   }
//   return getMessaging(app);
// };
//
// let loading = false;
//
// const setLoading = (value) => {
//   loading = value;
// };
//
// export const deleteTokenFromFirebase = async () => {
//   const firebaseService = new FirebaseService();
//   let messaging;
//   try {
//     messaging = await getMessagingInstance();
//   } catch (error) {
//     console.error(error);
//     return;
//   }
//   const token = localStorage.getItem("firebaseToken");
//
//   try {
//     // Remover token do notificador, se existir
//     if (token) {
//       await firebaseService.removerTokenDeNotificador(token);
//     }
//
//     // Remover token do Firebase Messaging
//     await deleteToken(messaging);
//
//     // Remover token do localStorage
//     localStorage.removeItem("firebaseToken");
//   } catch (error) {
//     console.error("Erro ao deletar token:", error);
//   }
// };
// export const requestNotificationPermission = async () => {
//   const firebaseService = new FirebaseService();
//   let messaging;
//   try {
//     messaging = await getMessagingInstance();
//   } catch (error) {
//     console.error(error);
//     return;
//   }
//   const usuario = new UsuarioService().usuarioCorrente();
//   if (!usuario || !usuario.legalTerm) {
//     console.error("Usuário não encontrado");
//     return;
//   }
//   return Notification.requestPermission().then((permission) => {
//     if (loading) {
//       return;
//     }
//     if (permission === "granted") {
//       setLoading(true);
//       return getToken(messaging, { vapidKey })
//         .then((token) => {
//           if (localStorage.getItem("firebaseToken") === token) {
//             return token;
//           }
//           firebaseService
//             .enviarTokenParaNotificador({
//               token,
//               userId: usuario.participantCode,
//               name: usuario.name ? usuario.name : usuario.email,
//               enrollment:
//                 usuario.enrollment !== null
//                   ? usuario.enrollment
//                   : usuario.email,
//             })
//             .then(
//               (response) => {
//                 localStorage.setItem("firebaseToken", token);
//                 setLoading(false);
//                 return token;
//               },
//               (error) => {
//                 setLoading(false);
//                 console.error("Erro ao enviar token:", error);
//               },
//             );
//         })
//         .catch((err) => {
//           console.error("Unable to get token", err);
//         });
//     } else {
//       setLoading(false);
//       throw new Error("Permissão negada");
//     }
//   });
// };
//
// // Solicitar permissão de notificação
import UsuarioService from "./services/UsuarioService";
import NotificadorService from "./services/NotificadorService";

export const requestPermission = async () => {
  const notificadorService = new NotificadorService();
  const usuario = new UsuarioService().usuarioCorrente();

  try {
    const notificarUser = localStorage.getItem("notificador-user");
    if (notificarUser && notificarUser === usuario.participantCode) {
      return;
    }
    await notificadorService.enviarTokenParaNotificador({
      token: null,
      userId: usuario.participantCode,
      name: usuario.name || usuario.email,
      enrollment: usuario.enrollment || usuario.email,
    });

    //adicionar no localStorage
    localStorage.setItem("notificador-user", usuario.participantCode);
  } catch (notificationError) {
    console.error("Erro ao enviar token nulo:", notificationError);
  }
};
//
// // Função para lidar com uma única mensagem recebida usando Promise
// export const onMessageListener = async () => {
//   let messaging;
//   try {
//     messaging = await getMessagingInstance();
//   } catch (error) {
//     console.error(error);
//     return;
//   }
//   return new Promise((resolve, reject) => {
//     try {
//       onMessage(messaging, (payload) => {
//         resolve(payload);
//       });
//     } catch (error) {
//       console.error("Erro ao configurar o listener de mensagem:", error);
//       reject(error);
//     }
//   });
// };
//
// // Configurar listener de mensagens com suporte a callback
// export const setupMessageListener = async (callback) => {
//   let messaging;
//   try {
//     messaging = await getMessagingInstance();
//   } catch (error) {
//     console.error(error);
//     return;
//   }
//
//   onMessage(messaging, (payload) => {
//     try {
//       // Se um callback for fornecido, executa-o
//       if (typeof callback === "function") {
//         callback(payload);
//       }
//     } catch (err) {
//       console.error("Erro ao processar mensagem:", err);
//     }
//   });
// };
