/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotificadorService from "../../../services/NotificadorService";
import UsuarioService from "../../../services/UsuarioService";
import BotaoPrimario from "../../../comps/button/BotaoPrimario";
import CampanhaService from "../../../services/CampanhaService";

const NotificationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const NotificationCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const NotificationHeader = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
`;

const NotificationTitle = styled.div`
  font-weight: bold;
`;

const NotificationBody = styled.div`
  padding: 20px;
`;

const NotificationMessage = styled.div`
  // Estilos padrão da plataforma
`;

// Novo container para o botão
const LoadMoreContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
`;

const NotificationList = () => {
  const notificationService = new NotificadorService();
  const usuarioCorrente = new UsuarioService().usuarioCorrente();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(true);
  const uuidCampanha = new CampanhaService()?.campanhaCorrente()?.uuid;
  const limit = 100;

  useEffect(() => {
    // Função para buscar a lista de notificações
    const fetchNotifications = async () => {
      try {
        const response = await notificationService.buscarMensagensParticipante({
          userId: usuarioCorrente.participantCode,
          event: uuidCampanha,
          page,
          limit,
        });
        setNotifications(response.data.data);

        if (
          response.data.pagination.currentPage !==
            response.data.pagination.totalPages &&
          response.data.pagination.totalPages !== 0
        ) {
          setLastPage(false);
        }
      } catch (error) {
        console.error("Erro ao buscar as notificações:", error);
      }
    };

    fetchNotifications();
  }, []);

  const handleLoadMore = async () => {
    try {
      const newPage = page + 1;
      const response = await notificationService.buscarMensagensParticipante({
        userId: usuarioCorrente.participantCode,
        page: newPage,
        limit,
      });
      setNotifications([...notifications, ...response.data.data]);
      setPage(newPage);
    } catch (error) {
      console.error("Erro ao buscar as notificações:", error);
    }
  };

  return (
    <NotificationContainer>
      {notifications?.map((notification) => {
        // Extrai os parâmetros "title" e "body" da lista de parâmetros
        const titleParam = notification.parameters.find(
          (param) => param.code === "title",
        );
        const bodyParam = notification.parameters.find(
          (param) => param.code === "body",
        );

        const title = titleParam?.value || "Sem título"; // Valor padrão caso não exista
        const body = bodyParam?.value || "Sem mensagem";

        return (
          <NotificationCard key={notification.id}>
            <NotificationHeader>
              <NotificationTitle>{title}</NotificationTitle>
            </NotificationHeader>
            <NotificationBody>
              <NotificationMessage>{body}</NotificationMessage>
            </NotificationBody>
          </NotificationCard>
        );
      })}
      {}
      <LoadMoreContainer>
        {
          // Renderiza o botão "Carregar mais" apenas se não for a última página
          !lastPage && (
            <BotaoPrimario
              disabledOnClick={false}
              nome="Carregar mais"
              size="small"
              onClick={handleLoadMore}
            />
          )
        }
      </LoadMoreContainer>
    </NotificationContainer>
  );
};

export default NotificationList;
