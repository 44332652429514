import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import LogosCampanha from "./comps/campanha/LogosCampanha";
import Footer from "./comps/footer/Footer";
import Header from "./comps/header/Header";
import { WeexModalFactory } from "./comps/weexModais/WeexModalFactory";
import WeexRoutes from "./routes/WeexRoutes";
import { Weex } from "./styles/AppStyle";

// Importe as funções do Firebase
import { HelmetProvider } from "react-helmet-async";
import DynamicScripts from "./comps/scrips/DynamicScripts";

function App() {
  const usuario = useSelector((state) => state.usuario);
  const usuariologado = useSelector((state) => state.usuariologado);
  const dispatch = useDispatch();

  const backgroundispresent = useSelector((state) => state.backgroundispresent);

  const containerRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(true);

  const campanha = useSelector((state) => state.campanha);

  const [scripts, setScripts] = useState([]);

  // function isIOS() {
  //   return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  // }

  useEffect(() => {
    const currentContainerRef = containerRef.current;
    const checkSize = (entries) => {
      if (currentContainerRef) {
        const width = currentContainerRef.offsetWidth;
        const newIsDesktop = width > 700;
        if (newIsDesktop !== isDesktop) {
          setIsDesktop(newIsDesktop);
          dispatch({ type: "isDesktopMode", payload: newIsDesktop });
        }
      }
    };

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => checkSize(entries));
    });

    if (currentContainerRef) {
      resizeObserver.observe(currentContainerRef);
      checkSize(); // Check size initially
    }

    return () => {
      if (currentContainerRef) {
        resizeObserver.unobserve(currentContainerRef);
      }
    };
  }, [dispatch, isDesktop]);

  // // useEffect específico para Firebase
  // useEffect(() => {
  //   // Verificar o status da permissão
  //
  //   if (isIOS()) {
  //     console.log(
  //       "iOS detectado: não vamos solicitar permissão ou configurar listener.",
  //     );
  //     return;
  //   }
  //
  //   // Primeiro, checamos se o objeto Notification existe
  //   if (typeof Notification === "undefined") {
  //     console.log("Este navegador não suporta notificações de desktop.");
  //     return;
  //   }
  //
  //   if (Notification.permission === "granted") {
  //     dispatch({ type: "notification-permission", payload: true });
  //   } else if (Notification.permission === "denied") {
  //     dispatch({ type: "notification-permission", payload: false });
  //   }
  //
  //   requestPermission();
  //   setupMessageListener((payload) => {
  //     //adicionar para adicionar no redux, mais um item na lista de notificação
  //     const data = payload.data;
  //     console.log("Nova notificação recebida:", data);
  //     dispatch({ type: "add-notification", payload: data });
  //   });
  //
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (campanha?.scripts) {
      setScripts(campanha.scripts);
    } else {
      setScripts([]);
    }
  }, [campanha, dispatch]);

  return (
    <HelmetProvider>
      <Weex
        $usuariologado={usuariologado}
        $usuario={usuario ? "true" : "false"}
        $backgroundispresent={backgroundispresent}
        className="weex"
        id="weex"
      >
        <Router>
          {scripts?.length > 0 && <DynamicScripts scripts={scripts} />}
          <WeexModalFactory />
          <Header />
          <LogosCampanha />
          <main className="weex__container" ref={containerRef}>
            <WeexRoutes />
          </main>
          <Footer />
        </Router>
      </Weex>
    </HelmetProvider>
  );
}

export default App;
